import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPrimaryElectionById, getElectionCandidates, submitCandidateVote, getCandidateSubmissionFee, submitCandidate, generateCandidateWallet, getConfig } from '../services/apiService';
import toast from 'react-hot-toast';
import { XMarkIcon, ClipboardIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { /* Candidate, */ DisplayCandidate, PrimaryElectionData, VoteSubmission } from '../types';
import Navbar from '../components/Navbar';
import { Toaster } from 'react-hot-toast';
import ReactMarkdown from 'react-markdown';
import { QRCodeSVG } from 'qrcode.react';
import { Provider } from 'react-redux';
import { store } from '../store';
import QRCode from 'react-qr-code';

const getPrimaryStatusName = (statusCode: number): string => {
  const statusMap: Record<number, string> = {
    1: 'Draft',
    2: 'Active',
    3: 'Pending Start',
    4: 'Ready for Voting',
    5: 'Voting Active',
    6: 'Voting Closed',
    7: 'Completed',
    8: 'Cancelled',
    9: 'Failed',
    10: 'Succeeded'
  };
  
  return statusMap[statusCode] || `Status ${statusCode}`;
};


const getPositionName = (position: number): string => {
  const statusMap: Record<number, string> = {
    1: "Treasurer 1",
    2: "Treasurer 2",
    3: "Treasurer 3",
    4: "Treasurer 4",
    5: "Treasurer 5",
    6: "Manager 1",
    7: "Manager 2",
    8: "Funding Lead",
    9: "Development Lead",
    10: "Marketing Lead"    
  };
  
  return statusMap[position];
};

const getTypeName = (type: number): string => {
  const statusMap: Record<number, string> = {
    1: 'Draft',
    2: 'General',
    3: 'Primary',
    4: 'Special',
  };
  
  return statusMap[type] || `Status ${type}`;
};



// The original component with minimal changes 
const PrimaryDetailContent = () => {
  const { id } = useParams<{ id: string }>();
  const [primary, setPrimary] = useState<PrimaryElectionData | null>(null);
  const [candidates, setCandidates] = useState<DisplayCandidate[]>([]);
  const [loading, setLoading] = useState(true);
  const [voteAmount, setVoteAmount] = useState('');
  const [selectedCandidate, setSelectedCandidate] = useState<DisplayCandidate | null>(null);
  const [showVoteModal, setShowVoteModal] = useState(false);
  const [transactionHash, setTransactionHash] = useState('');
  const [showSubmitCandidateModal, setShowSubmitCandidateModal] = useState(false);
  const [candidateSubmissionFee, setCandidateSubmissionFee] = useState<number | null>(null);
  const [govTokenTicker, setGovTokenTicker] = useState<string>('');
  const [candidateData, setCandidateData] = useState({
    name: '',
    description: '',
    twitter: '',
    discord: '',
    telegram: ''
  });
  const [isSubmittingCandidate, setIsSubmittingCandidate] = useState(false);
  const [candidateSubmissionError, setCandidateSubmissionError] = useState<string | null>(null);
  const [candidateSubmissionStep, setCandidateSubmissionStep] = useState<'form' | 'verification'>('form');
  const [verificationResponse, setVerificationResponse] = useState<{
    status: 'pending' | 'completed' | 'failed';
    verificationId: string;
    expectedAmount: number;
    address: string;
    amount: number;
  } | null>(null);
  const [candidateWalletData, setCandidateWalletData] = useState<{ address: string; verificationId: string } | null>(null);
  const [estimatedVotes, setEstimatedVotes] = useState<number | null>(null);
  const [powerLevel, setPowerLevel] = useState<string>('');
  const [isSubmittingVote, setIsSubmittingVote] = useState(false);

  const calculateVotePower = (amount: string) => {
    const numAmount = parseFloat(amount);
    const minFee =  0.1;
    const maxFee =  100000;
  
    if (isNaN(numAmount) || numAmount < minFee) {
      setEstimatedVotes(null);
      setPowerLevel('');
      return;
    }
  
    // Use the exact same constants as backend
    const SEMITONE_RATIO = Math.pow(2, 1 / 12);  // ≈1.059463
  
    // Normalize amount and apply cap exactly as backend does
    const normalizedAmount = Math.min(numAmount / minFee, maxFee / minFee);
  
    // Calculate musical octaves and votes using identical formula to backend
    const octaves = Math.log2(normalizedAmount);
    const semitones = octaves * 12;
  
    const votes = Math.floor(
      Math.pow(2, octaves * (2 / 3)) *
      Math.pow(SEMITONE_RATIO, semitones % 12)
    );
  
    // Calculate max votes for power level thresholds using same formula as backend
    const maxVotes = Math.floor(
      Math.pow(2, Math.log2(maxFee / minFee) * (2 / 3))
    );
  
    // Use exact same thresholds as backend
    const pianoThreshold = Math.floor(maxVotes * 0.05);        // 5% of max
    const mezzoPianoThreshold = Math.floor(maxVotes * 0.15);   // 15% of max  
    const mezzoForteThreshold = Math.floor(maxVotes * 0.35);   // 35% of max
    const forteThreshold = Math.floor(maxVotes * 0.70);        // 70% of max
  
    // Determine power level using identical logic to backend
    let level = '';
    if (votes <= pianoThreshold) level = 'Piano';
    else if (votes <= mezzoPianoThreshold) level = 'Mezzo-Piano';
    else if (votes <= mezzoForteThreshold) level = 'Mezzo-Forte';
    else if (votes <= forteThreshold) level = 'Forte';
    else level = 'Fortissimo';
  
    setEstimatedVotes(votes);
    setPowerLevel(level);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [primaryData, feeData] = await Promise.all([
          getPrimaryElectionById(Number(id)),
          getCandidateSubmissionFee()
        ]);

        if (!feeData || typeof feeData.fee !== 'number') {
          console.error('Invalid fee data received:', feeData);
          toast.error('Failed to load submission fee');
          return;
        }

        setCandidateSubmissionFee(feeData.fee);

        // Set the primary data
        setPrimary(primaryData);
        console.log('primaryData', primaryData);
        
        // Transform candidates for display, defaulting to empty array if primary_candidates is undefined
        const displayCandidates: DisplayCandidate[] = Array.isArray((primaryData as any).primary_candidates) 
          ? (primaryData as any).primary_candidates.map((c: any) => ({
              id: c.id,
              name: c.name,
              description: c.data?.description || '',
              twitter: c.twitter || null,
              discord: c.discord || null,
              telegram: c.telegram || null,
              created: c.created,
              type: c.type,
              status: c.status,
              votes_count: c.candidate_votes?.reduce((sum: number, vote: any) => 
                sum + (vote.validvote ? (vote.votescounted || 0) : 0), 0) || 0,
              wallet: c.candidate_wallets_candidate_wallets_candidate_idToelection_candidates?.[0]?.address || 
                     c.candidate_wallets_election_candidates_walletTocandidate_wallets?.address || null
            }))
          : [];

          console.log('displayCandidates', displayCandidates);
        
        setCandidates(displayCandidates);
        
      } catch (error) {
        console.error('Error fetching primary data:', error);
        toast.error('Failed to load primary data');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    const fetchCandidateSubmissionFee = async () => {
      try {
        const { fee } = await getCandidateSubmissionFee();
        setCandidateSubmissionFee(fee);
      } catch (error) {
        console.error('Error fetching candidate submission fee:', error);
        toast.error('Failed to load candidate submission fee');
      }
    };

    fetchCandidateSubmissionFee();
  }, []);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config = await getConfig();
        setGovTokenTicker(config.govTokenTicker);
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };
    fetchConfig();
  }, []);

  /* Commenting out unused function to fix build error
  const handleVoteSubmit = async () => {
    if (!selectedCandidate || !voteAmount || !transactionHash || !selectedCandidate.wallet) {
      toast.error('Please fill in all required fields');
      return;
    }

    const voteData: VoteSubmission = {
      candidate_id: selectedCandidate.id,
      toaddress: selectedCandidate.wallet,
      amountsent: voteAmount,
      hash: transactionHash,
      created: new Date().toISOString(),
      validvote: true,
      election_id: Number(id)
    };

    try {
      await submitCandidateVote(voteData);
      toast.success('Vote submitted successfully');
      setShowVoteModal(false);
      // Clear inputs
      setVoteAmount('');
      setTransactionHash('');
      setSelectedCandidate(null);
      // Refresh data
      const candidatesData = await getElectionCandidates(Number(id));
      const updatedCandidates = candidatesData
        .filter((c: Candidate) => 
          primary?.candidates?.map(pc => Number(pc)).includes(c.id)
        )
        .map((c: Candidate): DisplayCandidate => ({
          id: c.id,
          name: c.name || '',
          description: c.data?.description || '',
          votes_count: c.candidate_votes?.length || 0,
          wallet: c.candidate_wallets_candidate_wallets_candidate_idToelection_candidates?.[0]?.address || null,
          twitter: c.twitter || null,
          discord: c.discord || null,
          telegram: c.telegram || null,
          created: c.created,
          type: c.type || null,
          status: c.status || null
        }));
      setCandidates(updatedCandidates);
    } catch (error: any) {
      toast.error(error.response?.data?.error || 'Failed to submit vote');
    }
  };
  */

  const handleCandidateSubmit = async () => {
    if (!candidateData.name || !candidateData.description) {
      toast.error('Name and description are required');
      return;
    }

    try {
      setIsSubmittingCandidate(true);
      setCandidateSubmissionError(null);

      // Generate wallet first
      const walletResponse = await generateCandidateWallet();
      setCandidateWalletData(walletResponse);

      console.log('walletResponse', walletResponse);
      console.log('candidateSubmissionFee', candidateSubmissionFee);
      console.log('verificationResponse', verificationResponse);
      console.log('candidateWalletData', candidateWalletData);
      // Store verification response for QR code display
      setVerificationResponse({
        status: 'pending',
        verificationId: walletResponse.verificationId,
        expectedAmount: candidateSubmissionFee!,
        address: walletResponse.address,
        amount: candidateSubmissionFee! // Add required amount field
      });

      console.log('verificationResponse', verificationResponse);

      // Move to verification step
      setCandidateSubmissionStep('verification');
    } catch (error: any) {
      console.error('Error generating wallet:', error);
      setCandidateSubmissionError(
        error.response?.data?.error || 
        error.message || 
        'Failed to generate wallet'
      );
    } finally {
      setIsSubmittingCandidate(false);
    }
  };

  const handleVerifyTransaction = async () => {

    try {
      setIsSubmittingCandidate(true);
      setCandidateSubmissionError(null);
      // Submit the candidate with verification
      await submitCandidate({
        ...candidateData,
        primaryId: Number(id),
        address: verificationResponse?.address!,
        expectedAmount: verificationResponse?.expectedAmount!,
        verificationId: verificationResponse?.verificationId,
      });

      // Refresh both primary and candidates data
      const [primaryData /* candidatesData */] = await Promise.all([
        getPrimaryElectionById(Number(id)),
        getElectionCandidates(Number(id)) // Still fetch candidates but don't destructure into a variable
      ]);

      setPrimary(primaryData);

      // @ts-ignore
      setCandidates(primaryData?.primary_candidates || []);
      
      // Reset form and close modal
      setShowSubmitCandidateModal(false);
      setCandidateData({
        name: '',
        description: '',
        twitter: '',
        discord: '',
        telegram: ''
      });
      setCandidateWalletData(null);
      setVerificationResponse(null);
      setCandidateSubmissionStep('form');
      
      toast.success('Candidate submitted successfully!');
    } catch (error: any) {
      console.error('Error submitting candidate:', error);
      setCandidateSubmissionError(
        error.response?.data?.error || 
        error.message || 
        'Failed to submit candidate'
      );
    } finally {
      setIsSubmittingCandidate(false);
    }
  };

  // Candidate submission modal
  const renderCandidateSubmissionModal = () => (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
        <div className="inline-block transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle max-h-[70vh] overflow-y-auto">
          <div className="absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              onClick={() => setShowSubmitCandidateModal(false)}
              className="rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 focus:outline-none"
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="sm:flex sm:items-start">
            <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
              <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">
                {candidateSubmissionStep === 'form' ? 'Submit Candidate' : 'Verify Transaction'}
              </h3>
              <div className="mt-2">
                {candidateSubmissionStep === 'form' ? (
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Name *
                      </label>
                      <input
                        type="text"
                        id="name"
                        value={candidateData.name}
                        onChange={(e) => setCandidateData({ ...candidateData, name: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Description *
                      </label>
                      <textarea
                        id="description"
                        value={candidateData.description}
                        onChange={(e) => setCandidateData({ ...candidateData, description: e.target.value })}
                        rows={4}
                        className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="twitter" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Twitter
                      </label>
                      <input
                        type="text"
                        id="twitter"
                        value={candidateData.twitter}
                        onChange={(e) => setCandidateData({ ...candidateData, twitter: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="discord" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Discord
                      </label>
                      <input
                        type="text"
                        id="discord"
                        value={candidateData.discord}
                        onChange={(e) => setCandidateData({ ...candidateData, discord: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="telegram" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Telegram
                      </label>
                      <input
                        type="text"
                        id="telegram"
                        value={candidateData.telegram}
                        onChange={(e) => setCandidateData({ ...candidateData, telegram: e.target.value })}
                        className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="space-y-4">
                    <div className="rounded-md bg-gray-800 p-4">
                      <div className="flex flex-col">
                        <div className="flex items-start">
                          <div className="flex-shrink-0">
                            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                          </div>
                          <div className="ml-3 flex-1 min-w-0">
                            <h3 className="text-sm font-medium text-gray-100">
                              Please send exactly {candidateSubmissionFee} {govTokenTicker} to verify your submission
                            </h3>
                            <div className="mt-2 flex items-center space-x-2">
                              <code className="rounded bg-gray-700 px-2 py-1 text-sm text-gray-100 truncate flex-1">
                                {candidateSubmissionFee} {govTokenTicker}
                              </code>
                              <button
                                onClick={() => {
                                  navigator.clipboard.writeText(`${candidateSubmissionFee}`);
                                  toast.success('Amount copied to clipboard');
                                }}
                                className="rounded p-1 hover:bg-gray-700 flex-shrink-0"
                              >
                                <ClipboardIcon className="h-5 w-5 text-gray-300" />
                              </button>
                            </div>
                            <div className="mt-4 bg-white p-4 rounded-lg flex justify-center">
                              <QRCodeSVG
                                value={`${verificationResponse?.address}?amount=${verificationResponse?.expectedAmount}&ticker=${govTokenTicker}`}
                                size={200}
                              />
                            </div>
                            <div className="mt-2 flex items-center space-x-2">
                              <code className="rounded bg-gray-700 px-2 py-1 text-sm text-gray-100 truncate flex-1">
                                {verificationResponse?.address}
                              </code>
                              <button
                                type="button"
                                onClick={() => {
                                  navigator.clipboard.writeText(verificationResponse?.address || '');
                                  toast.success('Address copied to clipboard');
                                }}
                                className="rounded p-1 hover:bg-gray-700 flex-shrink-0"
                              >
                                <ClipboardIcon className="h-5 w-5 text-gray-300" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {candidateSubmissionError && (
                      <div className="rounded-md bg-red-900 p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                          </div>
                          <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-200">{candidateSubmissionError}</h3>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            {candidateSubmissionStep === 'form' ? (
              <button
                type="button"
                onClick={handleCandidateSubmit}
                disabled={isSubmittingCandidate || !candidateData.name || !candidateData.description}
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {isSubmittingCandidate ? 'Submitting...' : 'Submit'}
              </button>
            ) : (
              <button
                type="button"
                onClick={handleVerifyTransaction}
                disabled={isSubmittingCandidate}
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {isSubmittingCandidate ? 'Verifying...' : 'Verify Payment'}
              </button>
            )}
            <button
              type="button"
              onClick={() => setShowSubmitCandidateModal(false)}
              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 px-4 py-2 text-base font-medium text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="min-h-screen dark:bg-gray-900">
        <Navbar />
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900 dark:border-white"></div>
        </div>
      </div>
    );
  }

  if (!primary) {
    return (
      <div className="min-h-screen dark:bg-gray-900">
        <Navbar />
        <div className="text-center py-8">
          <p className="text-gray-500 dark:text-gray-400">Primary not found</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen dark:bg-gray-900">
      <Navbar />
      <Toaster />
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {/* Primary Info Card */}
        <div className="card mb-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <div className="w-12 h-12 rounded-full bg-gradient-to-r from-primary to-secondary flex items-center justify-center">
                  <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
              </div>
              <div>
                <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-2">{primary.title}</h2>
                <div className="flex flex-col space-y-2">
                  <div className="flex space-x-2">
                    <span className="px-3 py-1.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-200">
                      {getTypeName(primary.type) || 'Unknown Type'}
                    </span>
                    <span className="px-3 py-1.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200">
                      Primary Election
                    </span>
                  </div>
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    Created: {primary.created ? new Date(primary.created).toLocaleDateString('en-US', {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric'
                    }) : 'Not available'}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-4">
              {primary.votesactive && (
                <div className="hidden sm:block">
                  <div className="flex flex-col space-y-2 items-end">
                    <div className="text-sm font-medium text-green-500 dark:text-green-400">
                      Primary Voting Active
                    </div>
                    <div className="text-sm text-gray-500 dark:text-gray-400">
                      Closes: {primary.closevote ? new Date(primary.closevote).toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                      }) : 'Not set'}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="prose dark:prose-invert max-w-none">
            <ReactMarkdown>{primary.description || ''}</ReactMarkdown>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          {/* Status Card */}
          <div className="card">
            <h4 className="text-xl font-bold mb-4 flex justify-between">
              <span>Primary Status:</span>
              <span>{getPrimaryStatusName(primary.status) || 'Unknown'}</span>
            </h4>
            <div className="space-y-3">
              <p className="flex justify-between">
                <span>Voting Schedule:</span>
                <div className="text-right">
                  {primary.openvote && primary.closevote ? (
                    <>
                      <div className="font-medium">
                        Opens: {new Date(primary.openvote).toLocaleDateString('en-US', {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric'
                        })} @ {new Date(primary.openvote).toLocaleTimeString('en-US', {
                          hour: 'numeric',
                          minute: '2-digit',
                          timeZoneName: 'short'
                        })}
                      </div>
                      <div className="font-medium">
                        Closes: {new Date(primary.closevote).toLocaleDateString('en-US', {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric'
                        })} @ {new Date(primary.closevote).toLocaleTimeString('en-US', {
                          hour: 'numeric',
                          minute: '2-digit',
                          timeZoneName: 'short'
                        })}
                      </div>
                    </>
                  ) : (
                    <span className="font-medium">Pending Schedule</span>
                  )}
                </div>
              </p>
              <p className="flex justify-between">
                <span>Position:</span>
                <span className="font-medium text-right">
                  {getPositionName(primary.position) || 'Unknown Position'}
                </span>
              </p>
              <p className="flex justify-between">
                <span>Type:</span>
                <span className="font-medium">
                  {getTypeName(primary.type) || 'Unknown Type'}
                </span>
              </p>
            </div>
          </div>
          
          {/* Candidates Progress Card */}
          <div className="card">
            <h4 className="text-xl font-bold mb-4">Primary Progress</h4>
            <div className="space-y-3">
              <p className="flex justify-between">
                <span>Total Candidates:</span>
                <span className="font-medium">{candidates.length}</span>
              </p>
              <p className="flex justify-between">
                <span>Total Votes:</span>
                <span className="font-medium">
                  {candidates.reduce((sum, candidate) => sum + (candidate.votes_count || 0), 0)}
                </span>
              </p>
              {primary.votesactive && (
                <div className="mt-4">
                  <div className="text-center text-sm text-gray-500 dark:text-gray-400 mb-2">
                    Voting is currently active
                  </div>
                  {/* <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5">
                    <div className="bg-primary h-2.5 rounded-full" style={{ width: '100%' }}></div>
                  </div> */}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Candidates Card */}
        <div className="card">
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-xl font-bold">Primary Candidates</h4>
            <button
              onClick={() => setShowSubmitCandidateModal(true)}
              className="button-primary"
            >
              Submit Candidate
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {candidates.map((candidate) => (
              <div key={candidate.id} className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-5 border border-gray-200 dark:border-gray-700 flex flex-col">
                <div className="flex justify-between items-center mb-3">
                  <h5 className="text-lg font-semibold text-gray-900 dark:text-gray-100">{candidate.name}</h5>
                  <span className="px-2 py-1 bg-indigo-100 dark:bg-indigo-900 text-indigo-800 dark:text-indigo-200 text-xs font-medium rounded-full">
                    {candidate.votes_count || 0} votes
                  </span>
                </div>
                {candidate.description && (
                  <p className="text-sm text-gray-600 dark:text-gray-300 mb-4 flex-grow">
                    {candidate.description}
                  </p>
                )}
                <div className="mt-auto">
                  {/* Social Links */}
                  <div className="flex space-x-3 mb-4">
                    {candidate.twitter && (
                      <a
                        href={`https://twitter.com/${candidate.twitter}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:text-blue-600 transition-colors"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                        </svg>
                      </a>
                    )}
                    {candidate.discord && (
                      <span className="text-indigo-500 flex items-center" title={`Discord: ${candidate.discord}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z" />
                        </svg>
                      </span>
                    )}
                    {candidate.telegram && (
                      <a
                        href={`https://t.me/${candidate.telegram}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-400 hover:text-blue-500 transition-colors"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M11.944 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0a12 12 0 0 0-.056 0zm4.962 7.224c.1-.002.321.023.465.14a.506.506 0 0 1 .171.325c.016.093.036.306.02.472-.18 1.898-.962 6.502-1.36 8.627-.168.9-.499 1.201-.82 1.23-.696.065-1.225-.46-1.9-.902-1.056-.693-1.653-1.124-2.678-1.8-1.185-.78-.417-1.21.258-1.91.177-.184 3.247-2.977 3.307-3.23.007-.032.014-.15-.056-.212s-.174-.041-.249-.024c-.106.024-1.793 1.14-5.061 3.345-.48.33-.913.49-1.302.48-.428-.008-1.252-.241-1.865-.44-.752-.245-1.349-.374-1.297-.789.027-.216.325-.437.893-.663 3.498-1.524 5.83-2.529 6.998-3.014 3.332-1.386 4.025-1.627 4.476-1.635z" />
                        </svg>
                      </a>
                    )}
                  </div>
                  {primary.votesactive && (
                    <button
                      onClick={() => {
                        setSelectedCandidate(candidate);
                        setShowVoteModal(true);
                      }}
                      className="button-primary w-full py-2"
                    >
                      Vote for Candidate
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Vote Modal */}
        {showVoteModal && selectedCandidate && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg max-w-2xl w-full p-6 relative overflow-y-auto max-h-[90vh]">
            <button
              onClick={() => {
                setShowVoteModal(false);
                setTransactionHash('');
                setVoteAmount('');
              }}
                  className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>

                <h3 className="text-xl font-bold mb-4">Vote for {selectedCandidate.name}</h3>

                <div className="text-sm mb-4 bg-yellow-100 p-2 rounded-md text-yellow-800">You can only vote one time per election from a certain wallet address. Duplicate votes from the same wallet will result in your voted funds being lost.</div>

                <div className="space-y-4">
                  {/* Step 1: Send Tokens */}
                  <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
                    <h4 className="font-semibold mb-2">Step 1: Send Tokens</h4>
                    <p className="text-sm mb-4">Send {process.env.REACT_APP_GOV_TOKEN_TICKER} tokens to the candidate vote address:</p>

                    <div className="flex justify-center mb-4">
                      <QRCode value={selectedCandidate.wallet || ''} size={96} />
                    </div>

                    <div className="flex items-center justify-between bg-gray-200 dark:bg-gray-600 p-2 rounded">
                      <code className="text-sm">{selectedCandidate.wallet}</code>
                      <button
                        onClick={() => {
                          navigator.clipboard.writeText(selectedCandidate.wallet || '');
                          toast.success('Address copied!');
                        }}
                        className="text-primary hover:text-primary-dark"
                      >
                        <ClipboardIcon className="h-5 w-5" />
                      </button>
                    </div>
                  </div>

                  {/* Vote Calculator */}
                  <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
                    <h4 className="font-semibold mb-2">Vote Calculator</h4>
                    <p className="text-sm mb-4">Calculate your voting power before sending tokens:</p>

                    <input
                      type="number"
                      value={voteAmount}
                      onChange={(e) => {
                        const newAmount = e.target.value;
                        setVoteAmount(newAmount);
                        calculateVotePower(newAmount);
                      }}
                      min={0.1}
                      max={100000}
                      step="0.1"
                      placeholder={`Enter amount of ${process.env.REACT_APP_GOV_TOKEN_TICKER} tokens`}
                      className="w-full p-2 border rounded dark:bg-gray-600 dark:border-gray-500 mb-4"
                    />

                    {estimatedVotes !== null && (
                      <div className="text-sm">
                        <p>Calculated Votes: <span className="font-medium">{estimatedVotes}</span></p>
                        <p>Power Level: <span className="font-medium">{powerLevel}</span></p>

                        {/* Power Scale */}
                        <div className="mt-2 space-y-1">
                          <div className="flex space-x-1">
                            <div className={`h-2 flex-1 rounded ${powerLevel === 'Piano' ? 'bg-primary-light scale-y-110' : 'bg-gray-300 dark:bg-gray-600'}`}></div>
                            <div className={`h-2 flex-1 rounded ${powerLevel === 'Mezzo-Piano' ? 'bg-primary scale-y-110' : 'bg-gray-300 dark:bg-gray-600'}`}></div>
                            <div className={`h-2 flex-1 rounded ${powerLevel === 'Mezzo-Forte' ? 'bg-primary-dark scale-y-110' : 'bg-gray-300 dark:bg-gray-600'}`}></div>
                            <div className={`h-2 flex-1 rounded ${powerLevel === 'Forte' ? 'bg-secondary-light scale-y-110' : 'bg-gray-300 dark:bg-gray-600'}`}></div>
                            <div className={`h-2 flex-1 rounded ${powerLevel === 'Fortissimo' ? 'bg-secondary scale-y-110' : 'bg-gray-300 dark:bg-gray-600'}`}></div>
                          </div>
                          <div className="flex justify-between text-[10px] text-gray-500">
                            <div className="text-center w-1/5">Piano</div>
                            <div className="text-center w-1/5">Mezzo Piano</div>
                            <div className="text-center w-1/5">Mezzo Forte</div>
                            <div className="text-center w-1/5">Forte</div>
                            <div className="text-center w-1/5">Fortissimo</div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Step 2: Enter Transaction ID */}
                  <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
                    <h4 className="font-semibold mb-2">Step 2: Enter Transaction ID</h4>
                    <input
                      type="text"
                      value={transactionHash}
                      onChange={(e) => setTransactionHash(e.target.value)}
                      placeholder="Enter your transaction hash"
                      className="w-full p-2 border rounded dark:bg-gray-600 dark:border-gray-500"
                    />
                  </div>

                  {/* Submit Button */}
                  <button
                    onClick={async () => {
                      if (!transactionHash) {
                        toast.error('Please enter a transaction hash');
                        return;
                      }

                      setIsSubmittingVote(true);
                      const voteData: VoteSubmission = {
                        candidate_id: selectedCandidate.id,
                        toaddress: selectedCandidate.wallet || '',
                        amountsent: voteAmount,
                        hash: transactionHash,
                        created: new Date().toISOString(),
                        validvote: true,
                        election_id: primary.id
                      };

                      console.log('voteData', voteData);
                      
                      try {
                        const voteResponse = await submitCandidateVote(voteData);

                        if (voteResponse) {
                          toast.success('Vote submitted successfully');
                          setShowVoteModal(false);
                          setTransactionHash('');
                          setVoteAmount('');
                          // Refresh the candidates list to show updated vote counts
                          const candidatesData = await getElectionCandidates(Number(id));
                          console.log('candidatesData', candidatesData);

                          const displayCandidates: DisplayCandidate[] = Array.isArray((candidatesData as any)) 
                              ? (candidatesData as any).primary_candidates.map((c: any) => ({
                                  id: c.id,
                                  name: c.name,
                                  description: c.data?.description || '',
                                  twitter: c.twitter || null,
                                  discord: c.discord || null,
                                  telegram: c.telegram || null,
                                  created: c.created,
                                  type: c.type,
                                  status: c.status,
                                  votes_count: c.votes_count,
                                  wallet: c.candidate_wallets_candidate_wallets_candidate_idToelection_candidates?.[0]?.address || 
                                        c.candidate_wallets_election_candidates_walletTocandidate_wallets?.address || null
                                }))
                              : [];

                              console.log('displayCandidates', displayCandidates);
                            
                            setCandidates(displayCandidates);
                          
                          setCandidates(candidatesData as DisplayCandidate[]);
                        }
                      } catch (error: any) {
                        console.error('Vote submission error:', error);
                        toast.error('Hash already used or invalid transaction');
                      } finally {
                        setIsSubmittingVote(false);
                      }
                    }}
                    disabled={!transactionHash || isSubmittingVote}
                    className="button-primary w-full disabled:opacity-50 flex items-center justify-center"
                  >
                    {isSubmittingVote ? (
                      <>
                        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2"></div>
                        Submitting Vote...
                      </>
                    ) : (
                      'Submit Vote'
                    )}
                  </button>
                </div>
              </div>
            </div>
        )}

        {/* Candidate Submission Modal */}
        {showSubmitCandidateModal && renderCandidateSubmissionModal()}
      </div>
    </div>
  );
};

// This wrapper component ensures Redux is available even if this page is loaded directly
// This is the only change we need to make to fix the Redux error
const PrimaryDetail: React.FC = () => {
  return (
    <Provider store={store}>
      <PrimaryDetailContent />
    </Provider>
  );
};

export default PrimaryDetail; 