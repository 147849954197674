import React, { useEffect, useState } from 'react';
import { getElections, getElectionPrimaries, getElectionStatuses, getElectionTypes, getElectionPositions } from '../services/apiService';
import { PrimaryElection, Status, ElectionType, ElectionPosition, PrimaryElectionData, EnrichedElectionData } from '../types';
import { ElectionsTable } from '../components/ElectionsTable';
import { classNames } from '../utils/classNames';
import Navbar from '../components/Navbar';
import { ElectionWithPrimary } from '../types/index';

const Elections: React.FC = () => {
  const [elections, setElections] = useState<ElectionWithPrimary[]>([]);
  const [primaries, setPrimaries] = useState<PrimaryElection[]>([]);
  const [statuses, setStatuses] = useState<Status[]>([]);
  const [types, setTypes] = useState<ElectionType[]>([]);
  const [positions, setPositions] = useState<ElectionPosition[]>([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<'elections' | 'primaries'>('primaries');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [electionsData, primariesData, statusesData, typesData, positionsData] = await Promise.all([
          getElections(),
          getElectionPrimaries(),
          getElectionStatuses(),
          getElectionTypes(),
          getElectionPositions()
        ]);
        setElections(electionsData);
        setPrimaries(primariesData as PrimaryElection[]);
        setStatuses(statusesData);
        setTypes(typesData);
        setPositions(positionsData);
      } catch (error) {
        console.error('Error fetching elections:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
   
  }, []);

  

  const getEnrichedData = () => {
    if (activeTab === 'elections') {
      return elections.map((election): EnrichedElectionData => ({
        id: election.id,
        title: election.title,
        description: election.description,
        type: election.type,
        status: election.status,
        position: election.position,
        openvote: election.openvote,
        closevote: election.closevote,
        created: election.created,
        reviewed: election.reviewed,
        approved: election.approved,
        votesactive: election.votesactive,
        firstcandidate: election.firstcandidate,
        secondcandidate: election.secondcandidate,
        snapshot: election.snapshot,
        election_types: { name: types.find(t => t.id === election.type)?.name || 'Unknown' },
        election_statuses: { name: statuses.find(s => s.id === election.status)?.name || 'Unknown' },
        election_positions: { 
          title: positions.find(p => p.id === election.position)?.title || 'Unknown',
          description: positions.find(p => p.id === election.position)?.description || ''
        }
      }));
    } else {
      console.log('primaries', primaries);
      return primaries.map((primary): PrimaryElectionData => ({
        ...primary,
        created: primary.created,
        election_types: { name: types.find(t => t.id === primary.type)?.name || 'Unknown' },
        election_statuses: { name: statuses.find(s => s.id === primary.status)?.name || 'Unknown' },
        election_positions: {
          title: positions.find(p => p.id === primary.position)?.title || 'Unknown',
          description: positions.find(p => p.id === primary.position)?.description || ''
        },
        candidates_count: primary.candidates_count
      }));
    }
  };

  console.log('getEnrichedData', getEnrichedData());

  return (
    <div className="page-container">
      <Navbar />
      <div className="content-container">
        <div className="flex justify-between items-center mb-2">
          <h2 className="section-title">Elections</h2>
        </div>

        {loading ? (
          <div className="card p-4 text-center">
            <p className="text-gray-600 dark:text-gray-300">Loading elections...</p>
          </div>
        ) : elections.length === 0 && primaries.length === 0 ? (
          <div className="card p-4 text-center">
            <h3 className="text-xl font-bold mb-2">No Elections Yet</h3>
            <p className="text-gray-600 dark:text-gray-300">
              No elections have been created yet.
            </p>
          </div>
        ) : (
          <div className="card">
            <div className="border-b border-gray-200 dark:border-gray-700">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                
                <button
                  onClick={() => setActiveTab('primaries')}
                  className={classNames(
                    activeTab === 'primaries'
                      ? 'border-teal-500 text-teal-600 dark:text-teal-500'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300',
                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                  )}
                >
                  Primaries
                </button>
                <button
                  onClick={() => setActiveTab('elections')}
                  className={classNames(
                    activeTab === 'elections'
                      ? 'border-teal-500 text-teal-600 dark:text-teal-500'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300',
                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                  )}
                >
                  General Elections
                </button>
              </nav>
            </div>
            <ElectionsTable 
              elections={getEnrichedData()} 
              detailPath={activeTab === 'elections' ? '/election' : '/primary'}
              isPrimary={activeTab === 'primaries'}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Elections;