import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { 
  Candidate, 
  CandidateVote, 
  ElectionType, 
  ElectionStatus, 
  ElectionPosition, 
  NewElection, 
  UpdateElection
} from '../types';
import { ElectionWithPrimary } from '../types/index';

import {
  getElections,
  getElectionById,
  createElection,
  updateElection,
  deleteElection,
  createPrimaryElection,
  updatePrimaryElection,
  deletePrimaryElection,
  getCandidatesForElection,
  getCandidatesForPrimary,
  assignCandidateToElection,
  removeCandidateFromElection,
  submitCandidateVote,
  getCandidateVotes,
  updateElectionStatus,
  updatePrimaryElectionStatus,
} from '../services/apiService';

interface ElectionState {
  elections: ElectionWithPrimary[];
  currentElection: ElectionWithPrimary | null;
  candidates: Candidate[];
  votes: CandidateVote[];
  types: ElectionType[];
  statuses: ElectionStatus[];
  positions: ElectionPosition[];
  loading: boolean;
  error: string | null;
}

const initialState: ElectionState = {
  elections: [],
  currentElection: null,
  candidates: [],
  votes: [],
  types: [],
  statuses: [],
  positions: [],
  loading: false,
  error: null,
};

// Async thunks
export const fetchElections = createAsyncThunk(
  'elections/fetchElections',
  async () => {
    const response = await getElections();
    return response;
  }
);

export const fetchElectionById = createAsyncThunk(
  'elections/fetchElectionById',
  async (id: number) => {
    const response = await getElectionById(id);
    return response;
  }
);

export const addElection = createAsyncThunk(
  'elections/addElection',
  async (election: NewElection) => {
    const response = await createElection(election);
    return response;
  }
);

export const editElection = createAsyncThunk(
  'elections/editElection',
  async ({ id, election }: { id: number; election: UpdateElection }) => {
    const response = await updateElection(id, election);
    return response;
  }
);

export const removeElection = createAsyncThunk(
  'elections/removeElection',
  async (id: number) => {
    await deleteElection(id);
    return id;
  }
);

export const addPrimaryElection = createAsyncThunk(
  'elections/addPrimaryElection',
  async ({ electionId, data }: { electionId: number; data: { openvote: Date; closevote: Date; description: string } }) => {
    const response = await createPrimaryElection(electionId, data);
    return { electionId, primaryElection: response };
  }
);

export const editPrimaryElection = createAsyncThunk(
  'elections/editPrimaryElection',
  async ({ electionId, data }: { electionId: number; data: { openvote?: Date; closevote?: Date; description?: string } }) => {
    const response = await updatePrimaryElection(electionId, data);
    return { electionId, primaryElection: response };
  }
);

export const removePrimaryElection = createAsyncThunk(
  'elections/removePrimaryElection',
  async (electionId: number) => {
    await deletePrimaryElection(electionId);
    return electionId;
  }
);

export const fetchCandidates = createAsyncThunk(
  'elections/fetchCandidates',
  async (electionId: number) => {
    const response = await getCandidatesForElection(electionId);
    return response;
  }
);

export const fetchPrimaryCandidates = createAsyncThunk(
  'elections/fetchPrimaryCandidates',
  async (electionId: number) => {
    const response = await getCandidatesForPrimary(electionId);
    return response;
  }
);

export const assignCandidate = createAsyncThunk(
  'elections/assignCandidate',
  async ({ electionId, candidateId, isFirstCandidate }: { electionId: number; candidateId: number; isFirstCandidate: boolean }) => {
    const response = await assignCandidateToElection(electionId, candidateId, isFirstCandidate);
    return response;
  }
);

export const removeCandidate = createAsyncThunk(
  'elections/removeCandidate',
  async ({ electionId, candidateId }: { electionId: number; candidateId: number }) => {
    await removeCandidateFromElection(electionId, candidateId);
    return { electionId, candidateId };
  }
);

export const submitVote = createAsyncThunk(
  'elections/submitVote',
  async (vote: CandidateVote) => {
    const response = await submitCandidateVote(vote);
    return response;
  }
);

export const fetchVotes = createAsyncThunk(
  'elections/fetchVotes',
  async (candidateId: number) => {
    const response = await getCandidateVotes(candidateId);
    return response;
  }
);

export const changeElectionStatus = createAsyncThunk(
  'elections/changeElectionStatus',
  async ({ electionId, status }: { electionId: number; status: number }) => {
    const response = await updateElectionStatus(electionId, status);
    return response;
  }
);

export const changePrimaryElectionStatus = createAsyncThunk(
  'elections/changePrimaryElectionStatus',
  async ({ electionId, status }: { electionId: number; status: number }) => {
    const votesactive = status === 5; // Assuming status 5 means voting active
    const response = await updatePrimaryElectionStatus(electionId, status, votesactive);
    return { electionId, primaryElection: response };
  }
);

const electionSlice = createSlice({
  name: 'elections',
  initialState,
  reducers: {
    clearElectionError: (state) => {
      state.error = null;
    },
    clearCurrentElection: (state) => {
      state.currentElection = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Elections
      .addCase(fetchElections.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchElections.fulfilled, (state, action: PayloadAction<ElectionWithPrimary[]>) => {
        state.loading = false;
        state.elections = action.payload;
      })
      .addCase(fetchElections.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch elections';
      })
      
      // Fetch Election By Id
      .addCase(fetchElectionById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchElectionById.fulfilled, (state, action: PayloadAction<ElectionWithPrimary>) => {
        state.loading = false;
        state.currentElection = action.payload;
      })
      .addCase(fetchElectionById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch election';
      })
      
      // Add Election
      .addCase(addElection.pending, (state) => {
        state.loading = true;
      })
      .addCase(addElection.fulfilled, (state, action: PayloadAction<ElectionWithPrimary>) => {
        state.loading = false;
        state.elections.push(action.payload);
      })
      .addCase(addElection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to add election';
      })
      
      // Edit Election
      .addCase(editElection.pending, (state) => {
        state.loading = true;
      })
      .addCase(editElection.fulfilled, (state, action: PayloadAction<ElectionWithPrimary>) => {
        state.loading = false;
        const index = state.elections.findIndex((e: ElectionWithPrimary) => e.id === action.payload.id);
        if (index !== -1) {
          state.elections[index] = action.payload;
        }
        if (state.currentElection?.id === action.payload.id) {
          state.currentElection = action.payload;
        }
      })
      .addCase(editElection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to update election';
      })
      
      // Remove Election
      .addCase(removeElection.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeElection.fulfilled, (state, action: PayloadAction<number>) => {
        state.loading = false;
        state.elections = state.elections.filter((e: ElectionWithPrimary) => e.id !== action.payload);
        if (state.currentElection?.id === action.payload) {
          state.currentElection = null;
        }
      })
      .addCase(removeElection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to delete election';
      })
      
      // Add Primary Election
      .addCase(addPrimaryElection.pending, (state) => {
        state.loading = true;
      })
      .addCase(addPrimaryElection.fulfilled, (state, action) => {
        state.loading = false;
        const { electionId, primaryElection } = action.payload;
        const index = state.elections.findIndex((e: ElectionWithPrimary) => e.id === electionId);
        if (index !== -1) {
          state.elections[index] = {
            ...state.elections[index],
            primary: primaryElection,
          };
        }
        if (state.currentElection?.id === electionId) {
          state.currentElection = {
            ...state.currentElection,
            primary: primaryElection,
          };
        }
      })
      .addCase(addPrimaryElection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to create primary election';
      })
      
      // Edit Primary Election
      .addCase(editPrimaryElection.pending, (state) => {
        state.loading = true;
      })
      .addCase(editPrimaryElection.fulfilled, (state, action) => {
        state.loading = false;
        const { electionId, primaryElection } = action.payload;
        const index = state.elections.findIndex((e: ElectionWithPrimary) => e.id === electionId);
        if (index !== -1) {
          state.elections[index] = {
            ...state.elections[index],
            primary: primaryElection,
          };
        }
        if (state.currentElection?.id === electionId) {
          state.currentElection = {
            ...state.currentElection,
            primary: primaryElection,
          };
        }
      })
      .addCase(editPrimaryElection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to update primary election';
      })
      
      // Remove Primary Election
      .addCase(removePrimaryElection.pending, (state) => {
        state.loading = true;
      })
      .addCase(removePrimaryElection.fulfilled, (state, action: PayloadAction<number>) => {
        state.loading = false;
        const electionId = action.payload;
        const index = state.elections.findIndex((e: ElectionWithPrimary) => e.id === electionId);
        if (index !== -1) {
          state.elections[index] = {
            ...state.elections[index],
            primary: null,
          };
        }
        if (state.currentElection?.id === electionId) {
          state.currentElection = {
            ...state.currentElection,
            primary: null,
          };
        }
      })
      .addCase(removePrimaryElection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to delete primary election';
      })
      
      // Fetch Candidates
      .addCase(fetchCandidates.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCandidates.fulfilled, (state, action: PayloadAction<Candidate[]>) => {
        state.loading = false;
        state.candidates = action.payload;
      })
      .addCase(fetchCandidates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch candidates';
      })
      
      // Fetch Primary Candidates
      .addCase(fetchPrimaryCandidates.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPrimaryCandidates.fulfilled, (state, action: PayloadAction<Candidate[]>) => {
        state.loading = false;
        state.candidates = action.payload;
      })
      .addCase(fetchPrimaryCandidates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch primary candidates';
      })
      
      // Assign Candidate
      .addCase(assignCandidate.pending, (state) => {
        state.loading = true;
      })
      .addCase(assignCandidate.fulfilled, (state, action: PayloadAction<ElectionWithPrimary>) => {
        state.loading = false;
        const index = state.elections.findIndex((e: ElectionWithPrimary) => e.id === action.payload.id);
        if (index !== -1) {
          state.elections[index] = action.payload;
        }
        if (state.currentElection?.id === action.payload.id) {
          state.currentElection = action.payload;
        }
      })
      .addCase(assignCandidate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to assign candidate';
      })
      
      // Remove Candidate
      .addCase(removeCandidate.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeCandidate.fulfilled, (state, action) => {
        state.loading = false;
        const { electionId, candidateId } = action.payload;
        const election = state.elections.find((e: ElectionWithPrimary) => e.id === electionId);
        if (election) {
          if (election.firstcandidate === candidateId) {
            election.firstcandidate = null;
          }
          if (election.secondcandidate === candidateId) {
            election.secondcandidate = null;
          }
        }
        if (state.currentElection?.id === electionId) {
          if (state.currentElection.firstcandidate === candidateId) {
            state.currentElection.firstcandidate = null;
          }
          if (state.currentElection.secondcandidate === candidateId) {
            state.currentElection.secondcandidate = null;
          }
        }
      })
      .addCase(removeCandidate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to remove candidate';
      })
      
      // Submit Vote
      .addCase(submitVote.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitVote.fulfilled, (state, action: PayloadAction<CandidateVote>) => {
        state.loading = false;
        state.votes.push(action.payload);
      })
      .addCase(submitVote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to submit vote';
      })
      
      // Fetch Votes
      .addCase(fetchVotes.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchVotes.fulfilled, (state, action: PayloadAction<CandidateVote[]>) => {
        state.loading = false;
        state.votes = action.payload;
      })
      .addCase(fetchVotes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch votes';
      })
      
      // Change Election Status
      .addCase(changeElectionStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(changeElectionStatus.fulfilled, (state, action: PayloadAction<ElectionWithPrimary>) => {
        state.loading = false;
        const index = state.elections.findIndex((e: ElectionWithPrimary) => e.id === action.payload.id);
        if (index !== -1) {
          state.elections[index] = action.payload;
        }
        if (state.currentElection?.id === action.payload.id) {
          state.currentElection = action.payload;
        }
      })
      .addCase(changeElectionStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to update election status';
      })
      
      // Change Primary Election Status
      .addCase(changePrimaryElectionStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePrimaryElectionStatus.fulfilled, (state, action) => {
        state.loading = false;
        const { electionId, primaryElection } = action.payload;
        const index = state.elections.findIndex((e: ElectionWithPrimary) => e.id === electionId);
        if (index !== -1) {
          state.elections[index] = {
            ...state.elections[index],
            primary: primaryElection,
          };
        }
        if (state.currentElection?.id === electionId) {
          state.currentElection = {
            ...state.currentElection,
            primary: primaryElection,
          };
        }
      })
      .addCase(changePrimaryElectionStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to update primary election status';
      });
  },
});

export const { clearElectionError, clearCurrentElection } = electionSlice.actions;

export default electionSlice.reducer;

// Selectors
export const selectAllElections = (state: { elections: ElectionState }) => state.elections.elections;
export const selectCurrentElection = (state: { elections: ElectionState }) => state.elections.currentElection;
export const selectCandidates = (state: { elections: ElectionState }) => state.elections.candidates;
export const selectVotes = (state: { elections: ElectionState }) => state.elections.votes;
export const selectElectionLoading = (state: { elections: ElectionState }) => state.elections.loading;
export const selectElectionError = (state: { elections: ElectionState }) => state.elections.error;
export const selectActiveElections = (state: { elections: ElectionState }) => 
  state.elections.elections.filter((e: ElectionWithPrimary) => e.votesactive);
export const selectUpcomingElections = (state: { elections: ElectionState }) => 
  state.elections.elections.filter((e: ElectionWithPrimary) => !e.votesactive && new Date(e.openvote as any) > new Date());
export const selectPastElections = (state: { elections: ElectionState }) => 
  state.elections.elections.filter((e: ElectionWithPrimary) => !e.votesactive && new Date(e.closevote as any) < new Date());
export const selectElectionsWithPrimaries = (state: { elections: ElectionState }) => 
  state.elections.elections.filter((e: ElectionWithPrimary) => e.primary !== null);
export const selectPrimaryCandidates = (state: { elections: ElectionState }) => 
  state.elections.candidates.filter(c => state.elections.currentElection?.primary !== null);
export const selectGeneralCandidates = (state: { elections: ElectionState }) => 
  state.elections.candidates.filter((c: Candidate) => 
    state.elections.currentElection?.firstcandidate === c.id || 
    state.elections.currentElection?.secondcandidate === c.id
  ); 