import { ofetch } from 'ofetch';
import {
  Proposal,
  ProposalUpdate,
  Status,
  ProposalType,
  ProposalVote,
  CandidateVote,
  CandidateNomination,
  CandidateWallet,
  ElectionType,
  ElectionStatus,
  ElectionPosition,
  UpdateElection,
  NewElection,
  Candidate,
  NewCandidate,
  UpdateCandidate,
  BurnTransaction,
  GasDropTransaction,
  ProposalSnapshot,
  ProposalQueryParams,
  Config,
  TreasuryWallet,
  PrimaryElection,
  PrimaryElectionData,
  ElectionWithCandidates
} from '../types';
import { ElectionWithPrimary } from '../types/index';

const api = ofetch.create({
  baseURL: 'https://govapi.kaspadao.org/api',
});

// System Routes
export const getConfig = async (): Promise<Config> => {
  return await api('/config', {
    method: 'GET',
  });
};

export const getActiveProposalCount = async (): Promise<number> => {
  return await api('/active/proposal/count', {
    method: 'GET',
  });
};

export const getActiveElectionCount = async (): Promise<number> => {
  return await api('/active/election/count', {
    method: 'GET',
  });
};

export const getTreasuryWallets = async (): Promise<TreasuryWallet[]> => {
  return await api('/treasury/wallets', {
    method: 'GET',
  });
};

export const getTreasuryWalletTransactions = async (address: string): Promise<any[]> => {
  console.log('address', address);
  
  return await api(`/treasury/wallet/${address}/transactions`, {
    method: 'GET',
  });
};

// Proposal Management
export const getProposals = async (params: ProposalQueryParams = {}): Promise<Proposal[]> => {
  return await api('/proposals', {
    method: 'GET',
    params,
  });
};

export const createProposal = async (proposal: Proposal): Promise<Proposal> => {
  return await api('/proposal', {
    method: 'POST',
    body: proposal,
  });
};

export const getProposalById = async (proposalId: number): Promise<Proposal> => {
  return await api(`/proposal/${proposalId}`, {
    method: 'GET',
  });
};

export const updateProposal = async (proposalId: number, data: ProposalUpdate): Promise<Proposal> => {
  return await api(`/proposal/${proposalId}`, {
    method: 'PUT',
    body: data,
  });
};

// Proposal Types
export const getProposalTypes = async (): Promise<ProposalType[]> => {
  return await api('/proposal/types', {
    method: 'GET',
  });
};

export const createProposalType = async (type: ProposalType): Promise<ProposalType> => {
  return await api('/proposal/types', {
    method: 'POST',
    body: type,
  });
};

export const updateProposalType = async (typeId: number, type: ProposalType): Promise<ProposalType> => {
  return await api(`/proposal/types/${typeId}`, {
    method: 'PUT',
    body: type,
  });
};

export const deleteProposalType = async (typeId: number): Promise<void> => {
  await api(`/proposal/types/${typeId}`, {
    method: 'DELETE',
  });
};

// Proposal Statuses
export const getProposalStatuses = async (): Promise<Status[]> => {
  return await api('/proposal/statuses', {
    method: 'GET',
  });
};

export const createProposalStatus = async (status: Status): Promise<Status> => {
  return await api('/proposal/statuses', {
    method: 'POST',
    body: status,
  });
};

export const updateProposalStatus = async (statusId: number, status: Status): Promise<Status> => {
  return await api(`/proposal/statuses/${statusId}`, {
    method: 'PUT',
    body: status,
  });
};

export const deleteProposalStatus = async (statusId: number): Promise<void> => {
  await api(`/proposal/statuses/${statusId}`, {
    method: 'DELETE',
  });
};

// Proposal Voting
export const getProposalVotes = async (proposalId: number): Promise<ProposalVote[]> => {
  return await api(`/proposal/${proposalId}/votes`, {
    method: 'GET',
  });
};

export const getProposalYesVotes = async (): Promise<ProposalVote[]> => {
  return await api('/proposal/votes/yes', {
    method: 'GET',
  });
};

export async function submitProposalYesVote(proposal_id: number, transaction_hash: string): Promise<any> {
  try {
    const response = await api('/proposal/votes/yes', {
      method: 'POST',
      body: {
        proposal_id,
        transaction_hash
      }
    });
    return response;
  } catch (error) {
    console.error('Error submitting yes vote:', error);
    throw error;
  }
}

export const getProposalNoVotes = async (): Promise<ProposalVote[]> => {
  return await api('/proposal/votes/no', {
    method: 'GET',
  });
};

export async function submitProposalNoVote(proposal_id: number, transaction_hash: string): Promise<any> {
  try {
    const response = await api('/proposal/votes/no', {
      method: 'POST',
      body: {
        proposal_id,
        transaction_hash
      }
    });
    return response;
  } catch (error) {
    console.error('Error submitting no vote:', error);
    throw error;
  }
}

// Proposal Nominations
export const getProposalNominations = async (proposalId: number): Promise<any[]> => {
  if (!proposalId) {
    console.warn('No proposal ID provided for nominations fetch');
    return [];
  }

  try {
    const response = await api(`/proposal/${proposalId}/nominations`, {
      method: 'GET',
    });
    return response || [];
  } catch (error) {
    // Don't log 400 errors as they're expected when a proposal doesn't exist
    if (!(error instanceof Error && error.message.includes('400'))) {
      console.error('Error fetching nominations:', error);
    }
    return [];
  }
};

export const getProposalNominationCount = async (proposalId: number): Promise<number> => {
  return await api(`/proposal/${proposalId}/nominations/count`, {
    method: 'GET',
  });
};

export const getProposalNominationFee = async (): Promise<{ fee: number }> => {
  return await api('/proposal/nomination/fee', {
    method: 'GET',
  });
};

export const getProposalEditFee = async (): Promise<{ fee: number }> => {
  return await api('/proposal/edit/fee', {
    method: 'GET',
  });
};

export const getNominationVerificationStatus = async (proposalId: number, verificationId: string): Promise<any> => {
  return await api(`/proposal/${proposalId}/nominations/status`, {
    method: 'GET',
    params: { verificationId }
  });
};

export const verifyNominationTransaction = async (
  data: { fee: number; proposalId: number },
  onProgress?: (progress: { attempt: number; maxAttempts: number }) => void
): Promise<any> => {
  try {
    // Start the verification process
    const response = await api('/proposal/nomination/create', {
      method: 'POST',
      body: {
        fee: Number(data.fee),
        proposalId: Number(data.proposalId)
      }
    });

    if (response.status === 'pending' && response.verificationId) {
      const maxAttempts = 30; // 90 seconds total with 3-second intervals
      let attempt = 0;

      while (attempt < maxAttempts) {
        // Update progress callback
        if (onProgress) {
          onProgress({ attempt: attempt + 1, maxAttempts });
        }

        // Check verification status
        try {
          const verificationStatus = await getNominationVerificationStatus(data.proposalId, response.verificationId);

          if (verificationStatus.status === 'completed') {
            return {
              status: 'completed',
              nomination: verificationStatus.nomination,
              proposal: verificationStatus.proposal // Include updated proposal data
            };
          }
        } catch (error: any) {
          // If error is not 404 (not found), throw it
          if (!error.message?.includes('404')) {
            throw error;
          }
        }

        // Wait for the specified retry interval
        await new Promise(resolve => setTimeout(resolve, response.retryAfter * 1000));
        attempt++;
      }

      throw new Error('Verification timed out');
    }

    return response;
  } catch (error: any) {
    console.error('Error verifying nomination:', error);
    throw error;
  }
};

// Elections
export const getElections = async (): Promise<ElectionWithPrimary[]> => {
  return await api('/elections', {
    method: 'GET',
  });
};

export const getElectionPrimaries = async (): Promise<PrimaryElection[]> => {
  const response = await api('/elections/primaries', {
    method: 'GET',
  });
  
  // Map the response to our expected format without filtering
  // The returned data doesn't include parent_election_id but should have the election_id directly
  return response.map((primary: any) => ({
    ...primary,
    candidates: primary.candidates || [],
    // Use the id field as the primary ID and manually add parent_election_id if missing
    parent_election_id: primary.election_id || primary.id 
  }));
};

// Election Types
export const getElectionTypes = async (): Promise<ElectionType[]> => {
  return await api('/election/types', {
    method: 'GET',
  });
};

// Election Statuses
export const getElectionStatuses = async (): Promise<ElectionStatus[]> => {
  return await api('/election/statuses', {
    method: 'GET',
  });
};

// Election Positions
export const getElectionPositions = async (): Promise<ElectionPosition[]> => {
  return await api('/election/positions', {
    method: 'GET',
  });
};

// Create Election
export const createElection = async (election: NewElection): Promise<ElectionWithPrimary> => {
  return await api('/election', {
    method: 'POST',
    body: election,
  });
};

export const deleteElection = async (electionId: number): Promise<void> => {
  await api(`/election/${electionId}`, {
    method: 'DELETE',
  });
};

// Candidate Management
export const submitCandidateVote = async (vote: CandidateVote): Promise<CandidateVote> => {
  return await api('/candidate/vote', {
    method: 'POST',
    body: vote,
  });
};

export const getCandidateVotes = async (candidateId: number): Promise<CandidateVote[]> => {
  return await api(`/candidate/${candidateId}/votes`, {
    method: 'GET',
  });
};

export const getCandidateWallets = async (): Promise<CandidateWallet[]> => {
  return await api('/election/candidate/wallets', {
    method: 'GET',
  });
};

export const createCandidateWallet = async (wallet: CandidateWallet): Promise<CandidateWallet> => {
  return await api('/election/candidate/wallets', {
    method: 'POST',
    body: wallet,
  });
};

export const updateCandidateWallet = async (walletId: number, wallet: CandidateWallet): Promise<CandidateWallet> => {
  return await api(`/election/candidate/wallets/${walletId}`, {
    method: 'PUT',
    body: wallet,
  });
};

export const deleteCandidateWallet = async (walletId: number): Promise<void> => {
  await api(`/election/candidate/wallets/${walletId}`, {
    method: 'DELETE',
  });
};

export const getCandidateNominations = async (): Promise<CandidateNomination[]> => {
  return await api('/election/candidate/nominations', {
    method: 'GET',
  });
};

export const submitCandidateNomination = async (nomination: CandidateNomination): Promise<CandidateNomination> => {
  return await api('/election/candidate/nominations', {
    method: 'POST',
    body: nomination,
  });
};

export const updateCandidateNomination = async (nominationId: number, nomination: CandidateNomination): Promise<CandidateNomination> => {
  return await api(`/election/candidate/nominations/${nominationId}`, {
    method: 'PUT',
    body: nomination,
  });
};

export const deleteCandidateNomination = async (nominationId: number): Promise<void> => {
  await api(`/election/candidate/nominations/${nominationId}`, {
    method: 'DELETE',
  });
};

// Get Election By Id
export const getElectionById = async (id: number): Promise<ElectionWithPrimary> => {
  return await api(`/election/${id}`, {
    method: 'GET',
  });
};

// Update Election
export const updateElection = async (id: number, election: UpdateElection): Promise<ElectionWithPrimary> => {
  return await api(`/election/${id}`, {
    method: 'PUT',
    body: election,
  });
};

// Primary Election Management
export const createPrimaryElection = async (electionId: number, data: {
  openvote: Date;
  closevote: Date;
  description: string;
}): Promise<PrimaryElectionData> => {
  return await api(`/election/primary`, {
    method: 'POST',
    body: {
      ...data,
      election_id: electionId
    },
  });
};

export const updatePrimaryElection = async (electionId: number, data: {
  openvote?: Date;
  closevote?: Date;
  description?: string;
}): Promise<PrimaryElectionData> => {
  return await api(`/election/primary/${electionId}`, {
    method: 'PUT',
    body: data,
  });
};

export const deletePrimaryElection = async (electionId: number): Promise<void> => {
  await api(`/election/primary/${electionId}`, {
    method: 'DELETE',
  });
};

export const getCandidatesForElection = async (electionId: number): Promise<Candidate[]> => {
  return await api(`/election/${electionId}/candidates`, {
    method: 'GET',
  });
};

export const getCandidatesForPrimary = async (electionId: number): Promise<Candidate[]> => {
  return await api(`/primary/${electionId}/candidates`, {
    method: 'GET',
  });
};

export const assignCandidateToElection = async (
  electionId: number, 
  candidateId: number, 
  isFirstCandidate: boolean
): Promise<ElectionWithPrimary> => {
  return await api(`/election/${electionId}/candidate/${candidateId}`, {
    method: 'POST',
    body: { isFirstCandidate },
  });
};

export const removeCandidateFromElection = async (electionId: number, candidateId: number): Promise<void> => {
  await api(`/election/${electionId}/candidate/${candidateId}`, {
    method: 'DELETE',
  });
};

// Election Status Management
export const updateElectionStatus = async (electionId: number, status: number): Promise<ElectionWithPrimary> => {
  return await api(`/election/${electionId}/status`, {
    method: 'PUT',
    body: { status },
  });
};

export const updatePrimaryElectionStatus = async (
  electionId: number, 
  status: number,
  votesactive: boolean
): Promise<PrimaryElectionData> => {
  return await api(`/election/primary/${electionId}`, {
    method: 'PUT',
    body: { 
      status,
      votesactive
    },
  });
};

// Add these functions if they don't exist
export const getCandidateSubmissionFee = async (): Promise<{ fee: number }> => {
  return await api('/election/nomination/fee', {
    method: 'GET'
  });
};

export const generateCandidateWallet = async (): Promise<{ address: string; verificationId: string }> => {
  return await api('/election/candidate/wallet/generate', {
    method: 'POST'
  });
};

export const submitCandidate = async (data: {
  name: string;
  description: string;
  twitter?: string;
  discord?: string;
  telegram?: string;
  primaryId: number;
  verificationId?: string;
  address: string;
  expectedAmount: number;
}): Promise<any> => {
  return await api('/election/candidates', {
    method: 'POST',
    body: {
      name: data.name,
      twitter: data.twitter || null,
      discord: data.discord || null,
      telegram: data.telegram || null,
      type: 1, // Default to type 1 (candidate)
      status: 1, // Default to status 1 (active)
      created: new Date().toISOString(),
      data: {
        description: data.description,
        primaryId: data.primaryId,
        verificationId: data.verificationId,
        address: data.address,
        expectedAmount: data.expectedAmount
      }
    }
  });
};

// Add the general getCandidates function
export const getCandidates = async (): Promise<Candidate[]> => {
  try {
    const response = await api('/election/candidates', {
      method: 'GET'
    });
    return Array.isArray(response) ? response : [];
  } catch (error) {
    console.error('Error fetching candidates:', error);
    return [];
  }
};

// Add the getElectionsWithCandidates function
export const getElectionsWithCandidates = async (): Promise<ElectionWithCandidates[]> => {
  return await api('/elections/with-candidates', {
    method: 'GET'
  });
};

// Add the createDraftProposal function
export const createDraftProposal = async (): Promise<Proposal> => {
  return await api('/proposal', {
    method: 'POST',
    body: {
      title: "A draft proposal, please replace with the title of your proposal.",
      description: "Please replace this text with a short description of your proposal.",
      body: '',
      type: 1,
      status: 1, // Draft status
      submitted: new Date().toISOString(),
      reviewed: false,
      approved: false,
      passed: false,
      votesactive: false
    },
  });
};

// Add the createCandidateWithAssignment function
export const createCandidateWithAssignment = async (
  candidate: NewCandidate, 
  assignToElection?: number, 
  electionPosition?: 'first' | 'second'
): Promise<Candidate> => {
  return await api('/election/candidates', {
    method: 'POST',
    body: {
      ...candidate,
      assignToElection,
      electionPosition
    }
  });
};

// Add the modifyProposal function
export const modifyProposal = async (proposalId: number, proposalData: Partial<Proposal>) => {
  const response = await api(`/proposal/${proposalId}`, {
    method: 'PUT',
    body: proposalData
  });
  return response;
};

// Add the getElectionCandidates function
export const getElectionCandidates = async (id: number): Promise<Candidate[]> => {
  try {
    const response = await api(`/primary/${id}/candidates`, {
      method: 'GET'
    });
    return Array.isArray(response) ? response : [];
  } catch (error) {
    console.error('Error fetching election candidates:', error);
    return [];
  }
};

// Add the getPrimaryElectionById function
export const getPrimaryElectionById = async (id: number): Promise<PrimaryElectionData | null> => {
  try {
    // First, get all primaries to find the one with this ID
    const primaries = await getElectionPrimaries();
    
    // Find the primary with the given ID
    const primary = primaries.find(p => p.id === id);
    
    if (!primary) {
      console.error(`Primary with ID ${id} not found`);
      return null;
    }
    
    // Use the parent_election_id to fetch the complete primary data
    // BUT explicitly request the specific primary ID in the query parameters
    const response = await api(`/election/${primary.parent_election_id}/primary`, {
      method: 'GET',
      params: { primary_id: id }
    });
    
    // If we got a response, but it doesn't match the requested primary ID,
    // we need to filter the primaries to find the correct one
    if (response && response.id !== id) {
      console.warn('API returned wrong primary, searching for correct one');
      
      // Make a direct request to get all primaries
      const allPrimaries = await api('/elections/primaries', { 
        method: 'GET'
      });
      
      // Find our target primary with complete data
      const targetPrimary = allPrimaries.find((p: any) => p.id === id);
      
      if (!targetPrimary) {
        console.error(`Could not find primary with ID ${id} in all primaries`);
        return null;
      }
      
      return targetPrimary;
    }
    
    return response;
  } catch (error) {
    console.error('Error fetching primary election:', error);
    return null;
  }
};

// Add createElectionPosition
export const createElectionPosition = async (position: { title: string; description: string }): Promise<ElectionPosition> => {
  return await api('/election/positions', {
    method: 'POST',
    body: position,
  });
};

// Add deleteElectionPosition
export const deleteElectionPosition = async (id: number): Promise<void> => {
  await api(`/election/positions/${id}`, {
    method: 'DELETE',
  });
};

// Add updateElectionPosition
export const updateElectionPosition = async (id: number, position: { title: string; description: string }): Promise<ElectionPosition> => {
  return await api(`/election/positions/${id}`, {
    method: 'PUT',
    body: position,
  });
};

// Add wallet operation functions
export const burnKRC20 = async (transaction: BurnTransaction): Promise<void> => {
  await api('/burn/krc20', {
    method: 'POST',
    body: transaction,
  });
};

export const burnKaspa = async (transaction: BurnTransaction): Promise<void> => {
  await api('/burn/kaspa', {
    method: 'POST',
    body: transaction,
  });
};

export const returnGov = async (transaction: BurnTransaction): Promise<void> => {
  await api('/burn/return-gov', {
    method: 'POST',
    body: transaction,
  });
};

export const dropGas = async (transaction: GasDropTransaction): Promise<void> => {
  await api('/burn/drop-gas', {
    method: 'POST',
    body: transaction,
  });
};

// Add snapshot functions
export const createSnapshot = async (entityType: 'proposal' | 'election', entityId: number): Promise<ProposalSnapshot> => {
  return await api('/snapshot', {
    method: 'POST',
    body: { entityType, entityId }
  });
};

export async function getProposalSnapshots(): Promise<ProposalSnapshot[]> {
  try {
    const response = await api('/proposal/snapshots', {
      method: 'GET'
    });
    return response || [];
  } catch (error) {
    console.error('Error fetching proposal snapshots:', error);
    return [];
  }
}

// Add the createCandidate function
export const createCandidate = async (candidate: NewCandidate): Promise<Candidate> => {
  return await api('/election/candidates', {
    method: 'POST',
    body: {
      name: candidate.name,
      twitter: candidate.twitter || null,
      discord: candidate.discord || null,
      telegram: candidate.telegram || null,
      type: candidate.type,
      status: candidate.status,
      created: new Date().toISOString(),
      data: null,
      wallet: null,
      nominations: null
    },
  });
};

// Add the updateCandidate function
export const updateCandidate = async (id: number, candidate: UpdateCandidate): Promise<Candidate> => {
  return await api(`/election/candidates/${id}`, {
    method: 'PUT',
    body: candidate,
  });
};

// Add the deleteCandidate function
export const deleteCandidate = async (id: number): Promise<void> => {
  await api(`/election/candidates/${id}`, {
    method: 'DELETE',
  });
};

// Add the verifyProposalEdit function
export const verifyProposalEdit = async (
  proposalId: number,
  transactionHash: string,
  fee: number
): Promise<any> => {
  try {
    const response = await api(`/proposal/${proposalId}/verify-edit`, {
      method: 'POST',
      body: {
        transactionHash,
        fee
      }
    });
    return response;
  } catch (error) {
    console.error('Error verifying proposal edit:', error);
    throw error;
  }
};