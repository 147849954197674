import React, { useState } from 'react';
import { Proposal, Status } from '../types';
import { Link } from 'react-router-dom';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-hot-toast';

interface PublicProposalsTableProps {
  proposals: Proposal[];
  statuses: Status[];
}

const PublicProposalsTable: React.FC<PublicProposalsTableProps> = ({ proposals, statuses }) => {
  // Find the "Open for Nomination" status ID
  const openForNominationStatus = statuses.find(s => s.name === 'Open for Nomination');
  const [activeTab, setActiveTab] = useState<number | null>(openForNominationStatus?.id ?? null);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const toggleRow = (id: number) => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };
  const updatedProposals = proposals.map((proposal) => {
    const noVotes = proposal.proposal_no_votes_proposal_no_votes_proposal_idToproposals || [];
    const yesVotes = proposal.proposal_yes_votes_proposal_yes_votes_proposal_idToproposals || [];
  
    const total_no = noVotes.reduce((sum: any, vote: { votescounted: any; }) => sum + (vote.votescounted || 0), 0);
    const total_yes = yesVotes.reduce((sum: any, vote: { votescounted: any; }) => sum + (vote.votescounted || 0), 0);
  
    return {
      ...proposal,
      total_no,
      total_yes,
    };
  });

  // Filter out draft and rejected proposals first
  const publicProposals = updatedProposals.filter(proposal => {
    const status = statuses.find(s => s.id === proposal.status);
    return status && status.name !== 'Draft' && status.name !== 'Rejected';
  });

  // Then apply the active tab filter
  const displayProposals = activeTab === null
    ? publicProposals
    : publicProposals.filter((proposal) => proposal.status === activeTab);

  // Filter out draft and rejected from status tabs
  const publicStatuses = statuses.filter(status => 
    status.name !== 'Draft' && 
    status.name !== 'Rejected'
  );

  // Add helper function for vote countdown
  const getVoteCountdown = (endDate: string | null) => {
    if (!endDate) return null;
    const end = new Date(endDate);
    const now = new Date();
    const diff = end.getTime() - now.getTime();
    
    if (diff < 0) return 'Ended';
    
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    
    if (days > 0) return `${days}d ${hours}h`;
    return `${hours}h`;
  };

  console.log('proposals', proposals);

  
  
  console.log('updatedProposals', updatedProposals);

  // Add helper function for activity indicator
  const getActivityIndicator = (proposal: Proposal) => {
    const now = new Date().getTime();
    const submitted = proposal.submitted ? new Date(proposal.submitted).getTime() : 0;
    const hoursSinceSubmission = (now - submitted) / (1000 * 60 * 60);
    
    if (hoursSinceSubmission < 24) return 'High';
    if (hoursSinceSubmission < 72) return 'Medium';
    return 'Low';
  };

  return (
    <div className="space-y-6 animate-fadeIn">
      {/* Tab Navigation - Content-based width */}
      <div className="border-b border-gray-200 dark:border-gray-700">
        <div className="flex justify-between w-full">
          <button
            className={`px-6 py-3 text-sm font-medium transition-all duration-200 -mb-px whitespace-nowrap
              ${activeTab === null 
                ? 'border-b-2 border-primary text-primary dark:text-primary bg-gray-50 dark:bg-gray-800' 
                : 'text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800 hover:text-primary'}`}
            onClick={() => setActiveTab(null)}
          >
            All
          </button>
          {publicStatuses.map((status) => (
            <button
              key={status.id}
              className={`px-6 py-3 text-sm font-medium transition-all duration-200 -mb-px whitespace-nowrap
                ${activeTab === status.id 
                  ? 'border-b-2 border-primary text-primary dark:text-primary bg-gray-50 dark:bg-gray-800' 
                  : 'text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800 hover:text-primary'}`}
              onClick={() => setActiveTab(status.id ?? null)}
            >
              {status.name}
            </button>
          ))}
        </div>
      </div>

      {/* Enhanced Table */}
      <div className="overflow-hidden rounded-lg border border-gray-200 dark:border-gray-700 shadow-lg">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-50 dark:bg-gray-800">
            <tr>
              <th
                className="group px-6 py-4 text-center whitespace-nowrap text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort('title')}
              >
                <div className="flex items-center justify-center gap-2">
                  Title
                  <span className={`transition-opacity ${sortColumn === 'title' ? 'opacity-100' : 'opacity-0 group-hover:opacity-50'}`}>
                    {sortOrder === 'asc' ? '↑' : '↓'}
                  </span>
                </div>
              </th>
              <th
                className="group px-4 py-4 text-center whitespace-nowrap text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort('type')}
              >
                <div className="flex items-center justify-center gap-2">
                  Category
                  <span className={`transition-opacity ${sortColumn === 'type' ? 'opacity-100' : 'opacity-0 group-hover:opacity-50'}`}>
                    {sortOrder === 'asc' ? '↑' : '↓'}
                  </span>
                </div>
              </th>
              <th className="px-4 py-4 text-center whitespace-nowrap text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Submit Date
              </th>
              <th className="px-4 py-4 text-center whitespace-nowrap text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Qualified
              </th>
              <th className="px-4 py-4 text-center whitespace-nowrap text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Votes
              </th>
              <th className="px-4 py-4 text-center whitespace-nowrap text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Vote End
              </th>
              <th className="px-4 py-4 text-center whitespace-nowrap text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Activity
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700">
            {displayProposals.length === 0 ? (
              <tr>
                <td colSpan={7} className="px-6 py-8 text-center text-gray-500 dark:text-gray-400">
                  <div className="flex flex-col items-center gap-2">
                    <span className="text-lg">No proposals found</span>
                    <span className="text-sm">Check other tabs or come back later</span>
                  </div>
                </td>
              </tr>
            ) : (
              displayProposals.map((proposal) => (
                <React.Fragment key={proposal.id}>
                  <tr
                    className="group hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer transition-all duration-200"
                    onClick={() => proposal.id !== undefined && toggleRow(proposal.id)}
                  >
                    <td className="px-6 py-4">
                      <Link 
                        to={`/proposals/${proposal.id}`} 
                        className="text-gray-900 dark:text-gray-100 font-medium hover:text-primary transition-colors duration-200"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {proposal.title}
                      </Link>
                      {proposal.description && (
                        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400 line-clamp-2">
                          {proposal.description}
                        </p>
                      )}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-center">
                      <span className="px-3 py-1.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-200">
                        {proposal.proposal_types?.name || 'General'}
                      </span>
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-center">
                      {proposal.submitted ? new Date(proposal.submitted).toLocaleDateString() : 'N/A'}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-center">
                      <div className="flex flex-col items-center">
                        <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
                          {proposal.nominations_count ?? 0}/10
                        </span>
                        <div className="w-16 h-1.5 bg-gray-200 dark:bg-gray-700 rounded-full mt-1.5">
                          <div 
                            className="h-1.5 bg-primary rounded-full transition-all duration-500 ease-in-out"
                            style={{ width: `${Math.min(((proposal.nominations_count ?? 0) / 10) * 100, 100)}%` }}
                          />
                        </div>
                      </div>
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-center">                      
                      {proposal.votesactive ? (
                        <div className="flex flex-col items-center">                          
                          <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
                            {((proposal.total_yes || 0) + (proposal.total_no || 0)).toLocaleString()}
                          </span>
                          <div className="flex items-center gap-1 mt-1">
                            <div className="w-16 h-1.5 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                              <div className="h-full flex">
                                <div 
                                  className="h-full bg-green-500 transition-all duration-500 ease-in-out"
                                  style={{ 
                                    width: `${Math.round(((proposal.total_yes || 0) / ((proposal.yes_votes || 0) + (proposal.total_no || 0))) * 100) || 0}%` 
                                  }}
                                />
                                <div 
                                  className="h-full bg-red-500 transition-all duration-500 ease-in-out"
                                  style={{ 
                                    width: `${Math.round(((proposal.total_no || 0) / ((proposal.total_yes || 0) + (proposal.total_no || 0))) * 100) || 0}%` 
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center gap-2 text-xs mt-1">
                            <span className="text-green-600 dark:text-green-400">
                              {Math.round(((proposal.total_yes || 0) / ((proposal.total_yes || 0) + (proposal.total_no || 0))) * 100 || 0)}%
                            </span>
                            <span className="text-red-600 dark:text-red-400">
                              {Math.round(((proposal.total_no || 0) / ((proposal.total_no || 0) + (proposal.total_yes || 0))) * 100 || 0)}%
                            </span>
                          </div>
                        </div>
                      ) : (
                        <span className="text-sm text-gray-500 dark:text-gray-400">-</span>
                      )}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-center">
                      {proposal.votesactive ? (
                        <div className="flex flex-col items-center">
                          <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
                            {getVoteCountdown(proposal.closevote)}
                          </span>
                          <span className="text-xs text-gray-500 dark:text-gray-400 mt-1">remaining</span>
                        </div>
                      ) : (
                        <span className="text-sm text-gray-500 dark:text-gray-400">-</span>
                      )}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-center">
                      {(() => {
                        const activity = getActivityIndicator(proposal);
                        return (
                          <span className={`inline-flex items-center px-2.5 py-1.5 rounded-full text-xs font-medium
                            ${activity === 'High' 
                              ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200' 
                              : activity === 'Medium'
                                ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200'
                                : 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-200'
                            }`}
                          >
                            <span className={`w-1.5 h-1.5 rounded-full mr-1.5
                              ${activity === 'High' 
                                ? 'bg-green-500 animate-pulse' 
                                : activity === 'Medium'
                                  ? 'bg-yellow-500'
                                  : 'bg-gray-500'
                              }`}
                            />
                            {activity}
                          </span>
                        );
                      })()}
                    </td>
                  </tr>
                  {expandedRow === proposal.id && (
                    <tr className="bg-gray-50 dark:bg-gray-800">
                      <td colSpan={7} className="px-6 py-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                          <div className="space-y-4">
                            <div>
                              <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">Description</h4>
                              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">{proposal.description || 'No description provided'}</p>
                            </div>
                            <div>
                              <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">Creator</h4>
                              <div className="mt-2 flex items-center gap-2">
                                {proposal.proposal_wallets_proposals_walletToproposal_wallets?.address ? (
                                  <div className="flex items-center px-3 py-2 bg-gray-50 dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-600">
                                    <div className="flex-shrink-0">
                                      <div className="w-8 h-8 rounded-full bg-gradient-to-r from-primary to-secondary flex items-center justify-center">
                                        <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                        </svg>
                                      </div>
                                    </div>
                                    <div className="ml-3">
                                      <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
                                        <span className="font-mono">
                                          {`${proposal.proposal_wallets_proposals_walletToproposal_wallets.address.slice(0, 6)}...${
                                            proposal.proposal_wallets_proposals_walletToproposal_wallets.address.slice(-6)
                                          }`}
                                        </span>
                                      </p>
                                      <p className="text-xs text-gray-500 dark:text-gray-400">Proposal Creator</p>
                                    </div>
                                    <button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        const address = proposal.proposal_wallets_proposals_walletToproposal_wallets?.address;
                                        if (address) {
                                          navigator.clipboard.writeText(address);
                                          toast.success('Creator address copied to clipboard');
                                        }
                                      }}
                                      className="ml-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                                    >
                                      <ClipboardDocumentIcon className="h-5 w-5" />
                                    </button>
                                  </div>
                                ) : (
                                  <div className="text-sm text-gray-500 dark:text-gray-400">
                                    No one has claimed this proposal yet
                                  </div>
                                )}
                              </div>
                            </div>
                            <div>
                              <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">Type</h4>
                              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">{proposal.proposal_types?.name || 'N/A'}</p>
                            </div>
                          </div>
                          <div className="space-y-4">
                            <div className="grid grid-cols-2 gap-4">
                              <div>
                                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">Open Vote Date</h4>
                                <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                                  {proposal.openvote ? new Date(proposal.openvote).toLocaleDateString() : 'N/A'}
                                </p>
                              </div>
                              <div>
                                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">Close Vote Date</h4>
                                <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                                  {proposal.closevote ? new Date(proposal.closevote).toLocaleDateString() : 'N/A'}
                                </p>
                              </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <div>
                                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">Votes Active</h4>
                                <span className={`mt-1 inline-flex px-2 py-1 text-xs font-medium rounded-full
                                  ${proposal.votesactive
                                    ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                                    : 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-200'}`}>
                                  {proposal.votesactive ? 'Active' : 'Inactive'}
                                </span>
                              </div>
                              <div>
                                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">Passed</h4>
                                <span className={`mt-1 inline-flex px-2 py-1 text-xs font-medium rounded-full
                                  ${proposal.passed
                                    ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                                    : proposal.passed === false
                                      ? 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200'
                                      : 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-200'}`}>
                                  {proposal.passed === null ? 'Pending' : proposal.passed ? 'Yes' : 'No'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PublicProposalsTable; 