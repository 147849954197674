import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { UpdateElection, ElectionType, ElectionStatus, ElectionPosition } from '../../../types';
import { ElectionWithPrimary } from '../../../types/index';
import { updatePrimaryElection, updatePrimaryElectionStatus } from '../../../services/apiService';
import toast from 'react-hot-toast';

interface ElectionListProps {
  elections: ElectionWithPrimary[];
  selectedElection: ElectionWithPrimary | null;
  editedElection: UpdateElection | null;
  setEditedElection: (election: UpdateElection | null) => void;
  electionTypes: ElectionType[];
  electionStatuses: ElectionStatus[];
  electionPositions: ElectionPosition[];
  handleElectionSelect: (election: ElectionWithPrimary) => void;
  handleUpdateElection: () => Promise<void>;
  setSelectedElection: (election: ElectionWithPrimary | null) => void;
  handleManageCandidates?: (election: ElectionWithPrimary) => void;
}

const ElectionList: React.FC<ElectionListProps> = ({
  elections,
  selectedElection,
  editedElection,
  setEditedElection,
  electionTypes,
  electionStatuses,
  electionPositions,
  handleElectionSelect,
  handleUpdateElection,
  setSelectedElection,
  handleManageCandidates,
}) => {
  const [isPrimaryLoading, setIsPrimaryLoading] = useState(false);
  const [primaryDates, setPrimaryDates] = useState<{
    openvote: Date | null;
    closevote: Date | null;
  }>({
    openvote: null,
    closevote: null
  });
  
  const handleEditedElectionChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    if (!editedElection) return;

    setEditedElection({
      ...editedElection,
      [name]: type === 'checkbox' 
        ? (e.target as HTMLInputElement).checked 
        : (name === 'type' || name === 'position' || name === 'status' || name === 'firstcandidate' || name === 'secondcandidate') 
          ? Number(value) 
          : value
    });
  };

  const handlePrimaryVotingToggle = async (electionId: number, votesactive: boolean) => {
    if (!selectedElection?.primary?.id) return;
    
    try {
      setIsPrimaryLoading(true);
      const currentStatus = selectedElection.primary.status || 4;
      await updatePrimaryElectionStatus(electionId, currentStatus, votesactive);
      toast.success(`Primary voting ${votesactive ? 'enabled' : 'disabled'} successfully`);
      
      // Update the local state to reflect the change
      if (selectedElection && selectedElection.primary) {
        setSelectedElection({
          ...selectedElection,
          primary: {
            ...selectedElection.primary,
            votesactive: votesactive
          }
        });
      }
    } catch (error) {
      console.error('Error updating primary voting status:', error);
      toast.error('Failed to update primary voting status');
    } finally {
      setIsPrimaryLoading(false);
    }
  };

  const handleUpdatePrimaryDates = async () => {
    if (!selectedElection?.id || !selectedElection?.primary?.id) return;
    
    try {
      setIsPrimaryLoading(true);
      await updatePrimaryElection(selectedElection.id, {
        openvote: primaryDates.openvote || undefined,
        closevote: primaryDates.closevote || undefined
      });
      toast.success('Primary election dates updated successfully');
      
      // Update the local state to reflect the change
      if (selectedElection && selectedElection.primary) {
        setSelectedElection({
          ...selectedElection,
          primary: {
            ...selectedElection.primary,
            openvote: primaryDates.openvote?.toISOString() || null,
            closevote: primaryDates.closevote?.toISOString() || null
          }
        });
      }
    } catch (error) {
      console.error('Error updating primary dates:', error);
      toast.error('Failed to update primary dates');
    } finally {
      setIsPrimaryLoading(false);
    }
  };

  // Initialize primary dates when an election is selected
  React.useEffect(() => {
    if (selectedElection?.primary) {
      setPrimaryDates({
        openvote: selectedElection.primary.openvote ? new Date(selectedElection.primary.openvote) : null,
        closevote: selectedElection.primary.closevote ? new Date(selectedElection.primary.closevote) : null
      });
    }
  }, [selectedElection]);

  if (!selectedElection) {
    return (
      <div className="space-y-4">
        <h3 className="text-xl font-semibold">Elections</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white dark:bg-gray-800 rounded-lg overflow-hidden">
            <thead className="bg-gray-100 dark:bg-gray-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Title</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Primary</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Voting</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
              {elections.map(election => (
                <tr 
                  key={election.id}
                  className="hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-150"
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">{election.id}</td>
                  <td className="px-6 py-4 text-sm text-gray-900 dark:text-gray-100">
                    <div className="font-medium">{election.title}</div>
                    <div className="text-xs text-gray-500 dark:text-gray-400 mt-1 max-w-md truncate">{election.description}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">
                      {election.statusName || 'Unknown'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                    {election.primary ? (
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200">
                        {election.primary.statusName || 'Active'}
                      </span>
                    ) : (
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300">
                        None
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {election.votesactive ? (
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200">
                        Active
                      </span>
                    ) : election.primary?.votesactive ? (
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200">
                        Primary Active
                      </span>
                    ) : (
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200">
                        Inactive
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <div className="flex space-x-2">
                      <button
                        className="px-3 py-1 text-xs bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-150"
                        onClick={() => handleElectionSelect(election)}
                      >
                        Edit
                      </button>
                      {handleManageCandidates && (
                        <button
                          className="px-3 py-1 text-xs bg-green-500 text-white rounded hover:bg-green-600 transition duration-150"
                          onClick={() => handleManageCandidates(election)}
                        >
                          Candidates
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  if (!editedElection) return null;

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-semibold">Edit Election</h3>
        <button
          onClick={() => setSelectedElection(null)}
          className="px-4 py-2 bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300 rounded hover:bg-gray-300 dark:hover:bg-gray-600"
        >
          Back to List
        </button>
      </div>

      <form className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="md:col-span-2">
            <div className="space-y-4">
              <div>
                <label htmlFor="title" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={editedElection.title}
                  onChange={handleEditedElectionChange}
                  className="input-field mt-1 w-full"
                />
              </div>

              <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Description
                </label>
                <textarea
                  id="description"
                  name="description"
                  value={editedElection.description}
                  onChange={handleEditedElectionChange}
                  className="input-field mt-1 w-full h-32"
                />
              </div>
            </div>
          </div>

          <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
            <div className="space-y-4">
              <h4 className="font-medium text-gray-900 dark:text-gray-100">Election Properties</h4>
              
              <div>
                <label htmlFor="type" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Type
                </label>
                <select
                  id="type"
                  name="type"
                  value={editedElection.type || ''}
                  onChange={handleEditedElectionChange}
                  className="select-field mt-1 w-full"
                >
                  <option value="">Select Type</option>
                  {electionTypes.map(type => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="position" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Position
                </label>
                <select
                  id="position"
                  name="position"
                  value={editedElection.position || ''}
                  onChange={handleEditedElectionChange}
                  className="select-field mt-1 w-full"
                >
                  <option value="">Select Position</option>
                  {electionPositions.map(position => (
                    <option key={position.id} value={position.id}>
                      {position.title}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="status" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Status
                </label>
                <select
                  id="status"
                  name="status"
                  value={editedElection.status || ''}
                  onChange={handleEditedElectionChange}
                  className="select-field mt-1 w-full"
                >
                  <option value="">Select Status</option>
                  {electionStatuses.map(status => (
                    <option key={status.id} value={status.id}>
                      {status.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="space-y-2">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="reviewed"
                    name="reviewed"
                    checked={editedElection.reviewed}
                    onChange={handleEditedElectionChange}
                    className="form-checkbox h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <label htmlFor="reviewed" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                    Reviewed
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="approved"
                    name="approved"
                    checked={editedElection.approved}
                    onChange={handleEditedElectionChange}
                    className="form-checkbox h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <label htmlFor="approved" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                    Approved
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="votesactive"
                    name="votesactive"
                    checked={editedElection.votesactive}
                    onChange={handleEditedElectionChange}
                    className="form-checkbox h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <label htmlFor="votesactive" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                    Votes Active
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Open Vote Date
            </label>
            <DatePicker
              selected={editedElection.openvote ? new Date(editedElection.openvote) : null}
              onChange={(date: Date | null) => {
                setEditedElection({
                  ...editedElection,
                  openvote: date
                });
              }}
              showTimeSelect
              dateFormat="Pp"
              className="input-field w-full"
              placeholderText="Select open vote date"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Close Vote Date
            </label>
            <DatePicker
              selected={editedElection.closevote ? new Date(editedElection.closevote) : null}
              onChange={(date: Date | null) => {
                setEditedElection({
                  ...editedElection,
                  closevote: date
                });
              }}
              showTimeSelect
              dateFormat="Pp"
              className="input-field w-full"
              placeholderText="Select close vote date"
            />
          </div>
        </div>

        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={() => setSelectedElection(null)}
            className="px-4 py-2 bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300 rounded hover:bg-gray-300 dark:hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleUpdateElection}
            className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
          >
            Update Election
          </button>
        </div>
      </form>

      {/* Primary Election Management Section */}
      {selectedElection?.primary && (
        <div className="mt-10 p-6 bg-blue-50 dark:bg-blue-900/20 rounded-lg">
          <h3 className="text-xl font-semibold text-blue-900 dark:text-blue-100 mb-4">Primary Election Management</h3>
          
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <h4 className="font-medium text-blue-800 dark:text-blue-200">Primary Status</h4>
                <div>
                  <span className={`px-3 py-1 rounded-full text-xs font-medium ${
                    selectedElection.primary.votesactive 
                      ? 'bg-green-100 text-green-800 dark:bg-green-900/50 dark:text-green-200' 
                      : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/50 dark:text-yellow-200'
                  }`}>
                    {selectedElection.primary.votesactive ? 'Voting Active' : 'Voting Inactive'}
                  </span>
                </div>
              </div>
              
              <div className="space-y-2">
                <button
                  onClick={() => handlePrimaryVotingToggle(selectedElection.id, true)}
                  disabled={isPrimaryLoading || selectedElection.primary.votesactive}
                  className={`px-4 py-2 rounded text-white w-full ${
                    isPrimaryLoading || selectedElection.primary.votesactive
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-green-600 hover:bg-green-700'
                  }`}
                >
                  {isPrimaryLoading ? 'Processing...' : 'Enable Voting'}
                </button>
                
                <button
                  onClick={() => handlePrimaryVotingToggle(selectedElection.id, false)}
                  disabled={isPrimaryLoading || !selectedElection.primary.votesactive}
                  className={`px-4 py-2 rounded text-white w-full ${
                    isPrimaryLoading || !selectedElection.primary.votesactive
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-red-600 hover:bg-red-700'
                  }`}
                >
                  {isPrimaryLoading ? 'Processing...' : 'Disable Voting'}
                </button>
              </div>
              
              <div className="mt-4">
                <h4 className="font-medium text-blue-800 dark:text-blue-200 mb-2">Primary Information</h4>
                <ul className="space-y-2 text-sm">
                  <li className="flex justify-between">
                    <span className="text-gray-600 dark:text-gray-400">ID:</span>
                    <span className="font-medium">{selectedElection.primary.id}</span>
                  </li>
                  <li className="flex justify-between">
                    <span className="text-gray-600 dark:text-gray-400">Candidates:</span>
                    <span className="font-medium">{selectedElection.primary.candidateCount || 0}</span>
                  </li>
                  <li className="flex justify-between">
                    <span className="text-gray-600 dark:text-gray-400">Status:</span>
                    <span className="font-medium">{selectedElection.primary.statusName || 'Unknown'}</span>
                  </li>
                </ul>
              </div>
            </div>
            
            <div className="space-y-4">
              <h4 className="font-medium text-blue-800 dark:text-blue-200">Primary Voting Schedule</h4>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Primary Open Vote Date
                </label>
                <DatePicker
                  selected={primaryDates.openvote}
                  onChange={(date: Date | null) => {
                    setPrimaryDates(prev => ({ ...prev, openvote: date }));
                  }}
                  showTimeSelect
                  dateFormat="Pp"
                  className="input-field w-full"
                  placeholderText="Select primary open vote date"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Primary Close Vote Date
                </label>
                <DatePicker
                  selected={primaryDates.closevote}
                  onChange={(date: Date | null) => {
                    setPrimaryDates(prev => ({ ...prev, closevote: date }));
                  }}
                  showTimeSelect
                  dateFormat="Pp"
                  className="input-field w-full"
                  placeholderText="Select primary close vote date"
                />
              </div>
              
              <div className="pt-2">
                <button
                  onClick={handleUpdatePrimaryDates}
                  disabled={isPrimaryLoading}
                  className={`px-4 py-2 rounded text-white w-full ${
                    isPrimaryLoading
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-blue-600 hover:bg-blue-700'
                  }`}
                >
                  {isPrimaryLoading ? 'Updating...' : 'Update Primary Schedule'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ElectionList; 