import React, { useState, useEffect } from 'react';
import { Candidate, NewCandidate, UpdateCandidate, ElectionWithCandidates } from '../../../types';
import { ElectionWithPrimary } from '../../../types/index';
import { 
  getCandidates, 
  createCandidate, 
  createCandidateWithAssignment,
  updateCandidate, 
  deleteCandidate, 
  assignCandidateToElection, 
  removeCandidateFromElection,
  getElectionsWithCandidates
} from '../../../services/apiService';

interface ElectionCandidatesProps {
  elections: ElectionWithPrimary[];
  setUpdateMessage: (message: string | null) => void;
  currentElection?: ElectionWithPrimary | null;
}

const CANDIDATE_TYPES = [
  { id: 1, name: 'DRAFT' },
  { id: 2, name: 'Applied' },
  { id: 3, name: 'Active' },
  { id: 4, name: 'Incumbent' },
  { id: 5, name: 'Archived' }
];

const CANDIDATE_STATUSES = [
  { id: 1, name: 'DRAFT' },
  { id: 2, name: 'Submitted' },
  { id: 3, name: 'Reviewed' },
  { id: 4, name: 'Nominated' },
  { id: 5, name: 'Primary' },
  { id: 6, name: 'Election' },
  { id: 7, name: 'Winner' },
  { id: 8, name: 'Lost' },
  { id: 9, name: 'Archived' }
];

const ElectionCandidates: React.FC<ElectionCandidatesProps> = ({
  elections,
  setUpdateMessage,
  currentElection = null,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [candidatesList, setCandidatesList] = useState<Candidate[]>([]);
  const [selectedCandidate, setSelectedCandidate] = useState<Candidate | null>(null);
  const [editingCandidate, setEditingCandidate] = useState<UpdateCandidate | null>(null);
  const [selectedElectionForCandidate, setSelectedElectionForCandidate] = useState<ElectionWithPrimary | null>(currentElection);
  const [newCandidate, setNewCandidate] = useState<NewCandidate>({
    name: '',
    twitter: '',
    discord: '',
    telegram: '',
    type: 0,
    status: 0
  });
  const [directAssignment, setDirectAssignment] = useState(currentElection !== null);
  const [assignmentPosition, setAssignmentPosition] = useState<'first' | 'second' | ''>('');
  const [electionsWithCandidates, setElectionsWithCandidates] = useState<ElectionWithCandidates[]>([]);

  useEffect(() => {
    if (currentElection) {
      setSelectedElectionForCandidate(currentElection);
      setDirectAssignment(true);
    }
  }, [currentElection]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const candidatesData = await getCandidates();
        if (Array.isArray(candidatesData)) {
          setCandidatesList(candidatesData);
        }
        // Fetch elections with candidate data
        await fetchWithCandidates();
      } catch (error) {
        console.error('Error fetching data:', error);
        setUpdateMessage('Failed to load data');
        setCandidatesList([]);
        setElectionsWithCandidates([]);
      }
    };

    fetchData();
  }, [setUpdateMessage]);

  // Add the fetchWithCandidates function
  const fetchWithCandidates = async () => {
    try {
      // Fetch elections with candidate data
      const electionsWithCandidatesData = await getElectionsWithCandidates();
      if (Array.isArray(electionsWithCandidatesData)) {
        setElectionsWithCandidates(electionsWithCandidatesData);
      } else {
        console.error('Elections with candidates data is not an array:', electionsWithCandidatesData);
        setElectionsWithCandidates([]);
      }
    } catch (error) {
      console.error('Error fetching elections with candidates:', error);
      setElectionsWithCandidates([]);
    }
  };

  const handleNewCandidateSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (directAssignment && selectedElectionForCandidate?.id) {
        // Use the new endpoint that creates and assigns in one step
        await createCandidateWithAssignment(
          newCandidate, 
          selectedElectionForCandidate.id,
          assignmentPosition as 'first' | 'second' || undefined
        );
        setUpdateMessage('Candidate created and assigned to election successfully');
      } else {
        // Just create the candidate without assignment
        await createCandidate(newCandidate);
        setUpdateMessage('Candidate created successfully');
      }
      
      // Reset form
      setNewCandidate({
        name: '',
        twitter: '',
        discord: '',
        telegram: '',
        type: 0,
        status: 0
      });
      
      // Keep directAssignment state if we have a currentElection
      if (!currentElection) {
        setDirectAssignment(false);
      }
      
      setAssignmentPosition('');
      
      // Refresh data
      const updatedCandidates = await getCandidates();
      setCandidatesList(updatedCandidates);
      
      const updatedElectionsWithCandidates = await getElectionsWithCandidates();
      setElectionsWithCandidates(updatedElectionsWithCandidates);
    } catch (error) {
      console.error('Error creating candidate:', error);
      setUpdateMessage('Failed to create candidate');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCandidateUpdate = async () => {
    if (!selectedCandidate?.id || !editingCandidate) return;

    try {
      await updateCandidate(selectedCandidate.id, editingCandidate);
      setUpdateMessage('Candidate updated successfully');
      // Refresh candidates list
      const updatedCandidates = await getCandidates();
      setCandidatesList(updatedCandidates);
      // Reset selection
      setSelectedCandidate(null);
      setEditingCandidate(null);
    } catch (error) {
      console.error('Error updating candidate:', error);
      setUpdateMessage('Failed to update candidate');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCandidateDelete = async (id: number) => {
    if (window.confirm('Are you sure you want to delete this candidate? This action cannot be undone.')) {
      try {
        await deleteCandidate(id);
        setUpdateMessage('Candidate deleted successfully');
        // Refresh candidates list
        const updatedCandidates = await getCandidates();
        setCandidatesList(updatedCandidates);
      } catch (error) {
        console.error('Error deleting candidate:', error);
        setUpdateMessage('Failed to delete candidate');
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAssignCandidate = async (candidateId: number, isFirstCandidate: boolean) => {
    if (!selectedElectionForCandidate?.id) {
      setUpdateMessage('Please select an election first');
      return;
    }
    try {
      await assignCandidateToElection(selectedElectionForCandidate.id, candidateId, isFirstCandidate);
      setUpdateMessage('Candidate assigned to election successfully');
      // Update the selected election with the refreshed data
      const updatedElection = elections.find(e => e.id === selectedElectionForCandidate.id);
      setSelectedElectionForCandidate(updatedElection || null);
    } catch (error) {
      console.error('Error assigning candidate:', error);
      setUpdateMessage('Failed to assign candidate to election');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleRemoveFromElection = async (electionId: number, candidateId: number) => {
    try {
      await removeCandidateFromElection(electionId, candidateId);
      setUpdateMessage('Candidate removed from election successfully');
      // Update the selected election with the refreshed data
      const updatedElection = elections.find(e => e.id === electionId);
      setSelectedElectionForCandidate(updatedElection || null);
      // Scroll to top
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      console.error('Error removing candidate:', error);
      setUpdateMessage('Failed to remove candidate from election');
    }
  };

  // Add a new section to render elections with their candidates
  const renderElectionsWithCandidates = () => {
    return (
      <div className="mt-8">
        <h3 className="text-xl font-semibold mb-4">Elections with Candidates</h3>
        {electionsWithCandidates.length === 0 ? (
          <p>No elections found</p>
        ) : (
          <div className="grid grid-cols-1 gap-6">
            {electionsWithCandidates.map((election) => (
              <div key={election.id} className="bg-white p-4 rounded-lg shadow">
                <h4 className="text-lg font-medium">{election.title}</h4>
                <p className="text-gray-500 mb-2">{election.description}</p>
                <div className="mt-4">
                  <h5 className="font-medium">Candidates:</h5>
                  <div className="ml-4 mt-2">
                    <div className="mb-2">
                      <span className="font-medium">First Candidate:</span>{' '}
                      {election.candidates.first ? (
                        <span className="text-blue-600">{election.candidates.first.name}</span>
                      ) : (
                        <span className="text-gray-400">None assigned</span>
                      )}
                    </div>
                    <div>
                      <span className="font-medium">Second Candidate:</span>{' '}
                      {election.candidates.second ? (
                        <span className="text-blue-600">{election.candidates.second.name}</span>
                      ) : (
                        <span className="text-gray-400">None assigned</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  // Update the form to include direct assignment option
  const renderCandidateForm = () => {
    return (
      <form onSubmit={handleNewCandidateSubmit} className="bg-white p-4 rounded-lg shadow mt-4">
        <h3 className="text-xl font-semibold mb-4">
          {currentElection 
            ? `Add New Candidate to Election: ${currentElection.title}`
            : "Add New Candidate"
          }
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              value={newCandidate.name}
              onChange={(e) => setNewCandidate({ ...newCandidate, name: e.target.value })}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Twitter</label>
            <input
              type="text"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              value={newCandidate.twitter || ''}
              onChange={(e) => setNewCandidate({ ...newCandidate, twitter: e.target.value })}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Discord</label>
            <input
              type="text"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              value={newCandidate.discord || ''}
              onChange={(e) => setNewCandidate({ ...newCandidate, discord: e.target.value })}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Telegram</label>
            <input
              type="text"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              value={newCandidate.telegram || ''}
              onChange={(e) => setNewCandidate({ ...newCandidate, telegram: e.target.value })}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Type</label>
            <select
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              value={newCandidate.type}
              onChange={(e) => setNewCandidate({ ...newCandidate, type: parseInt(e.target.value) })}
              required
            >
              <option value="">Select Type</option>
              {CANDIDATE_TYPES.map((type) => (
                <option key={type.id} value={type.id}>{type.name}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Status</label>
            <select
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              value={newCandidate.status}
              onChange={(e) => setNewCandidate({ ...newCandidate, status: parseInt(e.target.value) })}
              required
            >
              <option value="">Select Status</option>
              {CANDIDATE_STATUSES.map((status) => (
                <option key={status.id} value={status.id}>{status.name}</option>
              ))}
            </select>
          </div>
        </div>

        {!currentElection && (
          <div className="mt-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="directAssignment"
                checked={directAssignment}
                onChange={(e) => setDirectAssignment(e.target.checked)}
                className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              />
              <label htmlFor="directAssignment" className="ml-2 block text-sm font-medium text-gray-700">
                Assign to election immediately
              </label>
            </div>
            
            {directAssignment && (
              <div className="mt-3 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Election</label>
                  <select
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    value={selectedElectionForCandidate?.id || ''}
                    onChange={(e) => {
                      const electionId = e.target.value ? parseInt(e.target.value) : null;
                      const election = elections.find(el => el.id === electionId) || null;
                      setSelectedElectionForCandidate(election);
                    }}
                    required={directAssignment}
                  >
                    <option value="">Select Election</option>
                    {elections.map((election) => (
                      <option key={election.id} value={election.id}>{election.title}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Position</label>
                  <select
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    value={assignmentPosition}
                    onChange={(e) => setAssignmentPosition(e.target.value as 'first' | 'second' | '')}
                  >
                    <option value="">Auto-assign to first available</option>
                    <option value="first">First Candidate</option>
                    <option value="second">Second Candidate</option>
                  </select>
                </div>
              </div>
            )}
          </div>
        )}
        
        {/* Always show position selection if we have a currentElection */}
        {currentElection && (
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Position</label>
            <select
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              value={assignmentPosition}
              onChange={(e) => setAssignmentPosition(e.target.value as 'first' | 'second' | '')}
            >
              <option value="">Auto-assign to first available</option>
              <option value="first">First Candidate</option>
              <option value="second">Second Candidate</option>
            </select>
          </div>
        )}

        <div className="mt-4">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Create Candidate
          </button>
        </div>
      </form>
    );
  };

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold mb-4">
        {currentElection 
          ? `Candidates for Election: ${currentElection.title}` 
          : "Election Candidates"
        }
      </h2>
      
      {/* Only show all elections with candidates if we don't have a currentElection */}
      {!currentElection && renderElectionsWithCandidates()}
      
      {/* If we have a currentElection, only show that election with its candidates */}
      {currentElection && (
        <div className="mt-8">
          <h3 className="text-xl font-semibold mb-4">Current Election Candidates</h3>
          <div className="bg-white p-4 rounded-lg shadow">
            <h4 className="text-lg font-medium">{currentElection.title}</h4>
            <p className="text-gray-500 mb-2">{currentElection.description}</p>
            <div className="mt-4">
              <h5 className="font-medium">Candidates:</h5>
              <div className="ml-4 mt-2">
                <div className="mb-2">
                  <span className="font-medium">First Candidate:</span>{' '}
                  {electionsWithCandidates.find(e => e.id === currentElection.id)?.candidates.first ? (
                    <span className="text-blue-600">
                      {electionsWithCandidates.find(e => e.id === currentElection.id)?.candidates.first?.name}
                    </span>
                  ) : (
                    <span className="text-gray-400">None assigned</span>
                  )}
                </div>
                <div>
                  <span className="font-medium">Second Candidate:</span>{' '}
                  {electionsWithCandidates.find(e => e.id === currentElection.id)?.candidates.second ? (
                    <span className="text-blue-600">
                      {electionsWithCandidates.find(e => e.id === currentElection.id)?.candidates.second?.name}
                    </span>
                  ) : (
                    <span className="text-gray-400">None assigned</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* Candidate form with direct assignment */}
      {renderCandidateForm()}
    </div>
  );
};

export default ElectionCandidates; 