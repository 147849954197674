import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { 
  useAppDispatch, 
  useAppSelector 
} from '../store/hooks';
import { 
  fetchElectionById, 
  selectCurrentElection, 
  selectPrimaryCandidates,
  selectGeneralCandidates,
  selectElectionLoading,
  selectElectionError,
  fetchCandidates,
  fetchPrimaryCandidates,
  submitVote,
} from '../store/electionSlice';
import Navbar from '../components/Navbar';
import { XMarkIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';

const getPrimaryStatusName = (statusCode: number): string => {
  const statusMap: Record<number, string> = {
    1: 'Draft',
    2: 'Active',
    3: 'Pending Start',
    4: 'Ready for Voting',
    5: 'Voting Active',
    6: 'Voting Closed',
    7: 'Completed',
    8: 'Cancelled',
    9: 'Failed',
    10: 'Succeeded'
  };
  
  return statusMap[statusCode] || `Status ${statusCode}`;
};

const getTypeName = (type: number): string => {
  const statusMap: Record<number, string> = {
    1: 'Draft',
    2: 'General',
    3: 'Primary',
    4: 'Special',
  };
  
  return statusMap[type] || `Status ${type}`;
};

const ElectionFlowContent: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const election = useAppSelector(selectCurrentElection);
  const primaryCandidates = useAppSelector(selectPrimaryCandidates);
  const generalCandidates = useAppSelector(selectGeneralCandidates);
  const loading = useAppSelector(selectElectionLoading);
  const error = useAppSelector(selectElectionError);
  const [tabValue, setTabValue] = useState(0);
  const [voteAmount, setVoteAmount] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [selectedCandidate, setSelectedCandidate] = useState<number | null>(null);
  const [isSubmittingVote, setIsSubmittingVote] = useState(false);
  const [voteSubmitted, setVoteSubmitted] = useState(false);
  const [transactionHash, setTransactionHash] = useState('');
  const [showSubmitCandidateModal, setShowSubmitCandidateModal] = useState(false);
  const [candidateSubmissionFee, setCandidateSubmissionFee] = useState<number | null>(null);
  const [govTokenTicker, setGovTokenTicker] = useState<string>('');
  const [candidateData, setCandidateData] = useState({
    name: '',
    description: '',
    twitter: '',
    discord: '',
    telegram: ''
  });
  const [isSubmittingCandidate, setIsSubmittingCandidate] = useState(false);
  const [candidateSubmissionError, setCandidateSubmissionError] = useState<string | null>(null);
  const [candidateSubmissionStep, setCandidateSubmissionStep] = useState<'form' | 'verification'>('form');
  const [verificationResponse, setVerificationResponse] = useState<{
    status: 'pending' | 'completed' | 'failed';
    verificationId: string;
    expectedAmount: number;
    address: string;
  } | null>(null);
  const [candidateWalletData, setCandidateWalletData] = useState<{ address: string; verificationId: string } | null>(null);

  useEffect(() => {
    if (id) {
      dispatch(fetchElectionById(Number(id)));
      dispatch(fetchCandidates(Number(id)));
      dispatch(fetchPrimaryCandidates(Number(id)));
    }
  }, [dispatch, id]);

  useEffect(() => {
    const fetchCandidateSubmissionFee = async () => {
      try {
        const { getCandidateSubmissionFee, getConfig } = await import('../services/apiService');
        const { fee } = await getCandidateSubmissionFee();
        setCandidateSubmissionFee(fee);

        const config = await getConfig();
        setGovTokenTicker(config.govTokenTicker);
      } catch (error) {
        console.error('Error fetching candidate submission fee:', error);
      }
    };

    fetchCandidateSubmissionFee();
  }, []);

  const handleTabChange = (newValue: number) => {
    setTabValue(newValue);
    setSelectedCandidate(null);
  };

  const renderElectionStatus = () => {
    if (!election) return null;

    return (
      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-4">Election Details</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <span className="block text-sm text-gray-500 dark:text-gray-400 mb-1">Status</span>
            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200">
              {election.statusName || `${getPrimaryStatusName(election.status)}`}
            </span>
          </div>
          
          <div>
            <span className="block text-sm text-gray-500 dark:text-gray-400 mb-1">Type</span>
            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">
              { `${getTypeName(election.type)}`}
            </span>
          </div>
          
          <div>
            <span className="block text-sm text-gray-500 dark:text-gray-400 mb-1">Voting</span>
            {election.votesactive ? (
              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200">
                Active
              </span>
            ) : (
              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-200">
                Inactive
              </span>
            )}
          </div>
          
          {election.hasActivePrimary && (
            <div>
              <span className="block text-sm text-gray-500 dark:text-gray-400 mb-1">Primary</span>
              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">
                Active Primary
              </span>
            </div>
          )}
          
          {election.isPrimary && (
            <div>
              <span className="block text-sm text-gray-500 dark:text-gray-400 mb-1">Election Type</span>
              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200">
                Primary Election
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderPrimarySection = () => {
    if (!election || !election.primary) return null;

    return (
      <div className="bg-white rounded-lg shadow mb-6 p-4">
        <h2 className="text-xl font-semibold mb-2">Primary Election</h2>
        <p className="text-gray-600 dark:text-gray-300 mb-4">
          {election.primary.description}
        </p>
        <div className="space-y-2">
          <p className="text-sm">
            Open Vote: {election.primary.openvote ? new Date(election.primary.openvote.toString()).toLocaleString() : 'Not set'}
          </p>
          <p className="text-sm">
            Close Vote: {election.primary.closevote ? new Date(election.primary.closevote.toString()).toLocaleString() : 'Not set'}
          </p>
          <p className="text-sm">
            Status: {election.primary.status}
          </p>
        </div>
        
        {election.primary && election.primary.status === 2 && (
          <div className="mt-4">
            <button 
              onClick={() => setShowSubmitCandidateModal(true)}
              className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
            >
              Submit Candidate
            </button>
          </div>
        )}
      </div>
    );
  };

  const handleSelectCandidate = (candidateId: number) => {
    setSelectedCandidate(candidateId === selectedCandidate ? null : candidateId);
  };

  const handleVoteSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedCandidate || !voteAmount || !transactionHash || !election) {
      toast.error('Please fill in all required fields');
      return;
    }
    
    setIsSubmittingVote(true);
    try {
      const voteData = {
        candidate_id: selectedCandidate,
        toaddress: walletAddress,
        amountsent: voteAmount,
        hash: transactionHash,
        created: new Date().toISOString(),
        validvote: true,
        electionId: election.id
      };
      
      await dispatch(submitVote(voteData));
      toast.success('Vote submitted successfully!');
      setVoteSubmitted(true);
      setVoteAmount('');
      setWalletAddress('');
      setTransactionHash('');
      setSelectedCandidate(null);
    } catch (error) {
      console.error("Vote submission error:", error);
      toast.error('Failed to submit vote');
    } finally {
      setIsSubmittingVote(false);
    }
  };

  const renderVotingForm = () => {
    if (!selectedCandidate || !election) return null;
    
    const isPrimaryTab = tabValue === 1;
    const isPrimaryActive = isPrimaryTab && election.primary?.status === 2;
    const isGeneralActive = !isPrimaryTab && election.status === 2;
    const isActive = isPrimaryActive || isGeneralActive;
    
    if (!isActive) {
      return (
        <div className="bg-blue-50 text-blue-800 p-4 rounded-lg">
          Voting is not currently active for this {isPrimaryTab ? 'primary' : 'general'} election.
        </div>
      );
    }
    
    if (voteSubmitted) {
      return (
        <div className="bg-green-50 text-green-800 p-4 rounded-lg">
          Your vote has been submitted successfully!
        </div>
      );
    }
    
    return (
      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-4">Submit Vote</h2>
        <form onSubmit={handleVoteSubmit} className="space-y-4">
          <div>
            <label htmlFor="walletAddress" className="block text-sm font-medium text-gray-700 mb-2">
              Candidate Wallet Address
            </label>
            <input
              id="walletAddress"
              type="text"
              value={walletAddress}
              onChange={(e) => setWalletAddress(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          
          <div>
            <label htmlFor="voteAmount" className="block text-sm font-medium text-gray-700 mb-2">
              Vote Amount
            </label>
            <input
              id="voteAmount"
              type="text"
              value={voteAmount}
              onChange={(e) => setVoteAmount(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          
          <div>
            <label htmlFor="transactionHash" className="block text-sm font-medium text-gray-700 mb-2">
              Transaction Hash
            </label>
            <input
              id="transactionHash"
              type="text"
              value={transactionHash}
              onChange={(e) => setTransactionHash(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          
          <button 
            type="submit" 
            className={`w-full px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors ${isSubmittingVote ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isSubmittingVote}
          >
            {isSubmittingVote ? 'Submitting...' : 'Submit Vote'}
          </button>
        </form>
      </div>
    );
  };

  const renderCandidates = () => {
    const candidates = tabValue === 0 ? generalCandidates : primaryCandidates;
    const isVotingActive = election?.votesactive || (election?.primary?.status === 2 && tabValue === 1);

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {candidates.length > 0 ? (
          candidates.map(candidate => (
            <div 
              key={candidate.id}
              className={`bg-white rounded-lg shadow p-4 ${
                selectedCandidate === candidate.id ? 'ring-2 ring-blue-500' : ''
              } ${isVotingActive ? 'cursor-pointer' : ''}`}
              onClick={isVotingActive ? () => handleSelectCandidate(candidate.id) : undefined}
            >
              <h3 className="text-lg font-semibold mb-2">{candidate.name}</h3>
              {candidate.twitter && (
                <p className="text-sm text-gray-600">Twitter: {candidate.twitter}</p>
              )}
              {candidate.discord && (
                <p className="text-sm text-gray-600">Discord: {candidate.discord}</p>
              )}
              {candidate.telegram && (
                <p className="text-sm text-gray-600">Telegram: {candidate.telegram}</p>
              )}
            </div>
          ))
        ) : (
          <div className="col-span-2 text-center py-8 text-gray-500">
            No candidates found
          </div>
        )}
      </div>
    );
  };

  const handleCandidateSubmit = async () => {
    if (!election?.primary || !verificationResponse?.address || !verificationResponse?.expectedAmount) {
      toast.error('Missing required data for candidate submission');
      return;
    }

    setIsSubmittingCandidate(true);
    setCandidateSubmissionError(null);

    try {
      const { submitCandidate } = await import('../services/apiService');
      // Submit the candidate with verification
      const submissionData: {
        name: string;
        description: string;
        twitter?: string;
        discord?: string;
        telegram?: string;
        primaryId: number;
        verificationId?: string;
        address: string;
        expectedAmount: number;
      } = {
        name: candidateData.name,
        description: candidateData.description,
        twitter: candidateData.twitter,
        discord: candidateData.discord,
        telegram: candidateData.telegram,
        primaryId: election.primary.id,
        verificationId: verificationResponse.verificationId,
        address: verificationResponse.address,
        expectedAmount: verificationResponse.expectedAmount
      };
      
      await submitCandidate(submissionData);
      toast.success('Candidate submitted successfully!');
      setShowSubmitCandidateModal(false);
      setCandidateSubmissionStep('form');
      setVerificationResponse(null);
      setCandidateData({
        name: '',
        description: '',
        twitter: '',
        discord: '',
        telegram: ''
      });
    } catch (error) {
      console.error('Candidate submission error:', error);
      setCandidateSubmissionError('Failed to submit candidate. Please try again.');
      toast.error('Failed to submit candidate');
    } finally {
      setIsSubmittingCandidate(false);
    }
  };

  const handleVerifyTransaction = async () => {
    if (!verificationResponse?.verificationId || !candidateWalletData || !election?.primary) return;

    try {
      setIsSubmittingCandidate(true);
      setCandidateSubmissionError(null);

      // Import only when needed
      const { submitCandidate } = await import('../services/apiService');
      // Submit the candidate with verification
      await submitCandidate({
        ...candidateData,
        primaryId: election.primary.id,
        verificationId: verificationResponse.verificationId,
        address: '',
        expectedAmount: 0
      });

      // Reset form and close modal
      setShowSubmitCandidateModal(false);
      setCandidateData({
        name: '',
        description: '',
        twitter: '',
        discord: '',
        telegram: ''
      });
      setCandidateWalletData(null);
      setVerificationResponse(null);
      setCandidateSubmissionStep('form');
      
      // Refresh data
      dispatch(fetchPrimaryCandidates(election.id));
    } catch (error: any) {
      console.error('Error submitting candidate:', error);
      setCandidateSubmissionError(
        error.response?.data?.error || 
        error.message || 
        'Failed to submit candidate'
      );
    } finally {
      setIsSubmittingCandidate(false);
    }
  };

  const renderCandidateSubmissionModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg max-w-2xl w-full mx-4 max-h-[80vh] overflow-auto relative">
        <button 
          onClick={() => setShowSubmitCandidateModal(false)}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
        
        <div className="p-6">
          <h2 className="text-xl font-semibold mb-6">
            {candidateSubmissionStep === 'form' ? 'Submit Candidate' : 'Verify Transaction'}
          </h2>
          
          {candidateSubmissionStep === 'form' ? (
            <div className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-2">
                  Name *
                </label>
                <input
                  type="text"
                  id="name"
                  value={candidateData.name}
                  onChange={(e) => setCandidateData({ ...candidateData, name: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-2">
                  Description *
                </label>
                <textarea
                  id="description"
                  value={candidateData.description}
                  onChange={(e) => setCandidateData({ ...candidateData, description: e.target.value })}
                  rows={4}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div>
                <label htmlFor="twitter" className="block text-sm font-medium text-gray-700 mb-2">
                  Twitter
                </label>
                <input
                  type="text"
                  id="twitter"
                  value={candidateData.twitter}
                  onChange={(e) => setCandidateData({ ...candidateData, twitter: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div>
                <label htmlFor="discord" className="block text-sm font-medium text-gray-700 mb-2">
                  Discord
                </label>
                <input
                  type="text"
                  id="discord"
                  value={candidateData.discord}
                  onChange={(e) => setCandidateData({ ...candidateData, discord: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div>
                <label htmlFor="telegram" className="block text-sm font-medium text-gray-700 mb-2">
                  Telegram
                </label>
                <input
                  type="text"
                  id="telegram"
                  value={candidateData.telegram}
                  onChange={(e) => setCandidateData({ ...candidateData, telegram: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="flex justify-end mt-6">
                <button
                  className={`px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors ${
                    isSubmittingCandidate || !candidateData.name || !candidateData.description 
                      ? 'opacity-50 cursor-not-allowed' 
                      : ''
                  }`}
                  onClick={handleCandidateSubmit}
                  disabled={isSubmittingCandidate || !candidateData.name || !candidateData.description}
                >
                  {isSubmittingCandidate ? 'Submitting...' : 'Next'}
                </button>
              </div>
            </div>
          ) : (
            <div className="space-y-6">
              <div className="bg-gray-50 p-6 rounded-lg">
                <div className="font-medium mb-4">
                  Please send exactly {candidateSubmissionFee} {govTokenTicker} to verify your submission
                </div>
                
                <div className="bg-white p-3 rounded flex items-center justify-between mb-4">
                  <span>{candidateSubmissionFee} {govTokenTicker}</span>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(`${candidateSubmissionFee}`);
                      toast.success('Amount copied to clipboard');
                    }}
                    className="text-blue-600 hover:text-blue-700"
                  >
                    Copy
                  </button>
                </div>
                
                <div className="bg-white p-6 rounded-lg mb-4 flex justify-center">
                  <div className="w-48 h-48 border border-gray-200 flex items-center justify-center">
                    QR Code would appear here
                  </div>
                </div>
                
                <div className="bg-white p-3 rounded flex items-center justify-between">
                  <span className="break-all">{verificationResponse?.address}</span>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(verificationResponse?.address || '');
                      toast.success('Address copied to clipboard');
                    }}
                    className="text-blue-600 hover:text-blue-700 ml-2"
                  >
                    Copy
                  </button>
                </div>
              </div>
              
              {candidateSubmissionError && (
                <div className="bg-red-50 text-red-700 p-4 rounded-lg">
                  {candidateSubmissionError}
                </div>
              )}
              
              <div className="flex justify-end">
                <button
                  className={`px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors ${
                    isSubmittingCandidate ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  onClick={handleVerifyTransaction}
                  disabled={isSubmittingCandidate}
                >
                  {isSubmittingCandidate ? 'Verifying...' : 'Verify Payment'}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 text-red-800 p-4 rounded-lg">
        {error}
      </div>
    );
  }

  if (!election) {
    return <div className="text-gray-500">Election not found</div>;
  }

  return (
    <div className="page-container">
      <div className="content-container  mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-4">
          {election.title}
        </h1>
        
        <p className="text-gray-600 dark:text-gray-300 mb-8">
          {election.description}
        </p>
        
        {renderElectionStatus()}
        
        {renderPrimarySection()}

        <div className="bg-white rounded-lg shadow mb-6">
          <div className="border-b border-gray-200">
            <div className="flex">
              <button
                onClick={() => handleTabChange(0)}
                className={`px-4 py-3 text-sm font-medium ${
                  tabValue === 0
                    ? 'border-b-2 border-blue-500 text-blue-600'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                General Candidates
              </button>
              <button
                onClick={() => handleTabChange(1)}
                className={`px-4 py-3 text-sm font-medium ${
                  tabValue === 1
                    ? 'border-b-2 border-blue-500 text-blue-600'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                Primary Candidates
              </button>
            </div>
          </div>
          
          <div className="p-4">
            {renderCandidates()}
          </div>
        </div>
        
        {renderVotingForm()}
        
        <div className="mt-6">
          <Link 
            to="/elections"
            className="inline-flex items-center px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
          >
            Back to Elections
          </Link>
        </div>

        {showSubmitCandidateModal && renderCandidateSubmissionModal()}
      </div>
      <Toaster position="top-right" />
    </div>
  );
};

// Wrapper component with Navbar
const ElectionFlow: React.FC = () => {
  return (
    <>
      <Navbar />
      <ElectionFlowContent />
    </>
  );
};

export default ElectionFlow; 