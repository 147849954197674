import React, { useState, useEffect, useCallback } from 'react';
import { 
  getElectionTypes, 
  getElectionStatuses, 
  getElectionPositions, 
  createElection, 
  getElections, 
  updateElection
} from '../../services/apiService';
import { NewElection, ElectionType, ElectionStatus, ElectionPosition, UpdateElection } from '../../types';
import { ElectionWithPrimary } from '../../types/index';
import ElectionForm from './elections/ElectionForm';
import ElectionList from './elections/ElectionList';
import ElectionPositions from './elections/ElectionPositions';
import ElectionCandidates from './elections/ElectionCandidates';
import { Toaster } from 'react-hot-toast';

interface ElectionsTabProps {
  updateMessage: string | null;
  setUpdateMessage: (message: string | null) => void;
}

export default function ElectionsTab({ updateMessage, setUpdateMessage }: ElectionsTabProps) {
  const [activeAction, setActiveAction] = useState<string | null>('review'); // Default to review for better UX
  const [electionTypes, setElectionTypes] = useState<ElectionType[]>([]);
  const [electionStatuses, setElectionStatuses] = useState<ElectionStatus[]>([]);
  const [electionPositions, setElectionPositions] = useState<ElectionPosition[]>([]);
  const [elections, setElections] = useState<ElectionWithPrimary[]>([]);
  const [selectedElection, setSelectedElection] = useState<ElectionWithPrimary | null>(null);
  const [editedElection, setEditedElection] = useState<UpdateElection | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newElection, setNewElection] = useState<NewElection>({
    title: '',
    description: '',
    type: 0,
    position: 0,
    status: 0,
    reviewed: false,
    approved: false,
    votesactive: false
  });

  // Function to refresh elections data
  const refreshElections = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await getElections();
      setElections(data);
    } catch (error) {
      console.error('Error refreshing elections:', error);
      setUpdateMessage('Failed to refresh elections data');
    } finally {
      setIsLoading(false);
    }
  }, [setUpdateMessage]);

  useEffect(() => {
    const fetchElectionData = async () => {
      try {
        const [types, statuses, positions] = await Promise.all([
          getElectionTypes(),
          getElectionStatuses(),
          getElectionPositions()
        ]);
        setElectionTypes(types);
        setElectionStatuses(statuses);
        setElectionPositions(positions);
      } catch (error) {
        console.error('Error fetching election data:', error);
        setUpdateMessage('Failed to load election data');
      }
    };

    fetchElectionData();
  }, [setUpdateMessage]);

  useEffect(() => {
    if (activeAction === 'review') {
      refreshElections();
    }
  }, [activeAction, refreshElections]);

  const handleElectionSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await createElection(newElection);
      setUpdateMessage('Election created successfully');
      // Reset the form
      setNewElection({
        title: '',
        description: '',
        type: 0,
        position: 0,
        status: 0,
        reviewed: false,
        approved: false,
        votesactive: false
      });
      // Refresh elections and switch to review tab
      await refreshElections();
      setActiveAction('review');
    } catch (error) {
      console.error('Error creating election:', error);
      setUpdateMessage('Failed to create election');
    } finally {
      setIsLoading(false);
    }
  };

  const handleElectionSelect = (election: ElectionWithPrimary) => {
    setSelectedElection(election);
    setEditedElection({
      title: election.title || '',
      description: election.description || '',
      type: election.type,
      position: election.position,
      status: election.status,
      reviewed: Boolean(election.reviewed),
      approved: Boolean(election.approved),
      votesactive: Boolean(election.votesactive),
      openvote: election.openvote ? new Date(election.openvote) : null,
      closevote: election.closevote ? new Date(election.closevote) : null,
      firstcandidate: election.firstcandidate || null,
      secondcandidate: election.secondcandidate || null
    });
  };

  const handleUpdateElection = async () => {
    if (!selectedElection?.id || !editedElection) return;

    try {
      setIsLoading(true);
      await updateElection(selectedElection.id, editedElection);
      setUpdateMessage('Election updated successfully');
      // Refresh elections list
      await refreshElections();
      // Reset selection
      setSelectedElection(null);
      setEditedElection(null);
    } catch (error) {
      console.error('Error updating election:', error);
      setUpdateMessage('Failed to update election');
    } finally {
      setIsLoading(false);
    }
  };

  const refreshPositions = async () => {
    try {
      const positions = await getElectionPositions();
      setElectionPositions(positions);
    } catch (error) {
      console.error('Error refreshing positions:', error);
      setUpdateMessage('Failed to refresh positions');
    }
  };

  return (
    <div className="space-y-8">
      <Toaster position="top-right" />
      
      {/* Notification Message */}
      {updateMessage && (
        <div className="p-4 bg-green-100 text-green-800 rounded text-center">
          {updateMessage}
        </div>
      )}

      {/* Loading indicator */}
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-xl">
            <div className="flex items-center space-x-4">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
              <p className="text-gray-700 dark:text-gray-300">Processing...</p>
            </div>
          </div>
        </div>
      )}

      {/* Actions toolbar */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-bold text-gray-900 dark:text-gray-100">Election Management</h2>
          <button
            onClick={refreshElections}
            disabled={isLoading}
            className="px-4 py-2 text-sm bg-blue-50 hover:bg-blue-100 text-blue-700 rounded-lg flex items-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>
            Refresh
          </button>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {[
            { id: 'review', label: 'Manage Elections' },
            { id: 'new', label: 'Create Election' },
            { id: 'positions', label: 'Manage Positions' },
            { id: 'candidates', label: 'Manage Candidates' }
          ].map(({ id, label }) => (
            <button
              key={id}
              onClick={() => {
                setActiveAction(id);
                setUpdateMessage(null);
                setSelectedElection(null);
                setEditedElection(null);
              }}
              className={`py-3 px-4 rounded-lg flex items-center justify-center transition-all duration-200
                ${activeAction === id 
                  ? 'bg-indigo-600 text-white shadow-md' 
                  : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
                }`}
            >
              {label}
            </button>
          ))}
        </div>
      </div>

      <div className="card p-6">
        {activeAction === 'new' && (
          <ElectionForm
            newElection={newElection}
            setNewElection={setNewElection}
            electionTypes={electionTypes}
            electionStatuses={electionStatuses}
            electionPositions={electionPositions}
            handleElectionSubmit={handleElectionSubmit}
          />
        )}

        {activeAction === 'review' && (
          <ElectionList
            elections={elections}
            selectedElection={selectedElection}
            editedElection={editedElection}
            setEditedElection={setEditedElection}
            electionTypes={electionTypes}
            electionStatuses={electionStatuses}
            electionPositions={electionPositions}
            handleElectionSelect={handleElectionSelect}
            handleUpdateElection={handleUpdateElection}
            setSelectedElection={setSelectedElection}
          />
        )}

        {activeAction === 'positions' && (
          <ElectionPositions
            positions={electionPositions}
            setUpdateMessage={setUpdateMessage}
            onPositionsChange={refreshPositions}
          />
        )}

        {activeAction === 'candidates' && (
          <ElectionCandidates
            elections={elections}
            setUpdateMessage={setUpdateMessage}
          />
        )}

        {!activeAction && (
          <div className="text-center text-gray-600 dark:text-gray-300">
            Select an action above to manage elections
          </div>
        )}
      </div>
    </div>
  );
} 