import React, { useState, useEffect } from 'react';
import { SHA256 } from 'crypto-js';
import Navbar from '../components/Navbar';
import ProposalsTab from '../components/management/ProposalsTab';
import ElectionsTab from '../components/management/ElectionsTab';
import TreasuryTab from '../components/management/TreasuryTab';
import PrimariesTab from '../components/management/PrimariesTab';

const Management: React.FC = () => {
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const [updateMessage, setUpdateMessage] = useState<string | null>(null);
  const MAX_ATTEMPTS = 5;
  const LOCK_DURATION = 5 * 60 * 1000; // 5 minutes
  const tabs = ['proposals', 'elections', 'primaries', 'treasury'] as const;
  type TabType = typeof tabs[number];
  const [activeTab, setActiveTab] = useState<TabType>('elections');

  const handleLogin = async () => {
    if (isLocked) {
      alert('Too many login attempts. Please try again later.');
      return;
    }

    const hashedPassword = SHA256(password).toString();
    const storedHash = process.env.REACT_APP_MANAGEMENT_PASSWORD_HASH || '';

    if (!storedHash) {
      console.error('Management password hash not found in environment variables');
      alert('Configuration error. Please contact administrator.');
      return;
    }

    if (hashedPassword === storedHash) {
      setIsAuthenticated(true);
      setLoginAttempts(0);
      const expirationTime = Date.now() + (30 * 60 * 1000);
      sessionStorage.setItem('mgmt_auth', '1');
      sessionStorage.setItem('mgmt_auth_expiry', expirationTime.toString());
    } else {
      const newAttempts = loginAttempts + 1;
      setLoginAttempts(newAttempts);
      
      if (newAttempts >= MAX_ATTEMPTS) {
        setIsLocked(true);
        setTimeout(() => {
          setIsLocked(false);
          setLoginAttempts(0);
        }, LOCK_DURATION);
      }
      
      alert('Incorrect password');
    }
    setPassword('');
  };

  // Check for existing auth on mount
  useEffect(() => {
    const checkAuth = () => {
      const isAuth = sessionStorage.getItem('mgmt_auth') === '1';
      const expiryTime = sessionStorage.getItem('mgmt_auth_expiry');
      
      if (isAuth && expiryTime) {
        const now = Date.now();
        if (now < parseInt(expiryTime)) {
          setIsAuthenticated(true);
        } else {
          // Clear expired session
          sessionStorage.removeItem('mgmt_auth');
          sessionStorage.removeItem('mgmt_auth_expiry');
          setIsAuthenticated(false);
        }
      }
    };

    checkAuth();

    // Check auth status every minute
    const interval = setInterval(checkAuth, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="page-container">
      <Navbar />
      <div className="content-container">
        {isAuthenticated ? (
          <div className="animate-fadeIn">
            {/* Tabs Navigation */}
            <div className="flex space-x-8 mb-8 border-b border-gray-200 dark:border-gray-700">
              {tabs.map((tab) => (
                <button
                  key={tab}
                  className={`px-6 py-4 text-lg font-medium capitalize transition-all duration-200
                    ${activeTab === tab 
                      ? 'border-b-2 border-primary text-primary translate-y-[2px]' 
                      : 'text-gray-600 dark:text-gray-300 hover:text-primary'}`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </button>
              ))}
            </div>

            {/* Tab Content */}
            {activeTab === 'proposals' && (
              <ProposalsTab
                updateMessage={updateMessage}
                setUpdateMessage={setUpdateMessage}
              />
            )}

            {activeTab === 'elections' && (
              <ElectionsTab
                updateMessage={updateMessage}
                setUpdateMessage={setUpdateMessage}
              />
            )}

            {activeTab === 'primaries' && (
              <PrimariesTab
                updateMessage={updateMessage}
                setUpdateMessage={setUpdateMessage}
              />
            )}

            {activeTab === 'treasury' && (
              <TreasuryTab
                updateMessage={updateMessage}
                setUpdateMessage={setUpdateMessage}
              />
            )}
          </div>
        ) : (
          <div className="card p-4 text-center max-w-md mx-auto">
            <h2 className="text-2xl font-bold mb-4">Authentication Required</h2>
            <p className="text-gray-600 dark:text-gray-300 mb-6">
              Please sign in to access management features.
            </p>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleLogin()}
              placeholder="Enter password"
              className="input-field mb-4"
            />
            <button 
              onClick={handleLogin} 
              className="button-primary w-full"
            >
              Sign In
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Management;
