import React, { useState, useEffect, useCallback } from 'react';
import {
  getElectionPrimaries,
  updatePrimaryElection,
  updatePrimaryElectionStatus,
  getElectionById
} from '../../services/apiService';
import { Toaster } from 'react-hot-toast';
import toast from 'react-hot-toast';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Status code mapping
const getPrimaryStatusName = (statusCode: number): string => {
  const statusMap: Record<number, string> = {
    1: 'Draft',
    2: 'Active',
    3: 'Pending Start',
    4: 'Ready for Voting',
    5: 'Voting Active',
    6: 'Voting Closed',
    7: 'Completed',
    8: 'Cancelled',
    9: 'Failed',
    10: 'Succeeded'
  };
  
  return statusMap[statusCode] || `Status ${statusCode}`;
};

interface PrimariesTabProps {
  updateMessage: string | null;
  setUpdateMessage: (message: string | null) => void;
}

// Create a display-specific interface that includes all the fields we need
interface PrimaryElectionDisplay {
  id: number;
  title: string;
  description: string;
  status: number;
  statusName?: string;
  openvote: Date | string | null;
  closevote: Date | string | null;
  votesactive: boolean;
  candidateCount: number;
  parent_election_id: number;
  election: {
    id: number;
    title: string;
    description: string;
    status: number;
    statusName?: string;
  };
}

const PrimariesTab: React.FC<PrimariesTabProps> = ({ updateMessage, setUpdateMessage }) => {
  const [primaries, setPrimaries] = useState<PrimaryElectionDisplay[]>([]);
  const [selectedPrimary, setSelectedPrimary] = useState<PrimaryElectionDisplay | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [primaryDates, setPrimaryDates] = useState<{
    openvote: Date | null;
    closevote: Date | null;
  }>({
    openvote: null,
    closevote: null
  });

  const fetchPrimaries = useCallback(async () => {
    try {
      setIsLoading(true);
      // Use the dedicated API for primaries
      const primariesData = await getElectionPrimaries();
      console.log('Fetched primary elections:', primariesData);

      // Create a list of display primaries directly
      const displayPrimaries: PrimaryElectionDisplay[] = [];
      
      // Process each primary
      for (const primary of primariesData) {
        try {
          // Check if we have a parent election ID
          const parentElectionId = primary.parent_election_id || (primary as any).election_id;
          
          // If we have a valid parent election, try to fetch it
          let parentElection = null;
          if (parentElectionId && parentElectionId > 0) {
            try {
              parentElection = await getElectionById(parentElectionId);
            } catch (error) {
              console.error(`Failed to fetch parent election ${parentElectionId}:`, error);
              // Continue even if we can't fetch the parent
            }
          }
          
          // Create the display primary with available data
          displayPrimaries.push({
            id: primary.id,
            title: primary.title || 'Untitled Primary',
            description: primary.description || '',
            status: primary.status || 0,
            statusName: getPrimaryStatusName(primary.status || 0),
            openvote: primary.openvote || null,
            closevote: primary.closevote || null,
            votesactive: primary.votesactive || false,
            candidateCount: primary.candidates?.length || 0,
            parent_election_id: parentElectionId || primary.id,
            election: parentElection ? {
              id: parentElection.id,
              title: parentElection.title,
              description: parentElection.description || '',
              status: parentElection.status || 0,
              statusName: parentElection.statusName || undefined
            } : {
              // Fallback election info if parent not available
              id: parentElectionId || primary.id,
              title: primary.title.replace('Primary: ', '') || 'Parent Election',
              description: primary.description.replace('Primary: ', '') || '',
              status: 0,
              statusName: undefined
            }
          });
        } catch (transformError) {
          console.error('Error transforming primary data:', primary.id, transformError);
        }
      }
      
      console.log('Transformed primaries data:', displayPrimaries);
      setPrimaries(displayPrimaries);
    } catch (error) {
      console.error('Error fetching primaries:', error);
      setUpdateMessage(`Failed to load primary elections: ${error instanceof Error ? error.message : 'Unknown error'}`);
    } finally {
      setIsLoading(false);
    }
  }, [setUpdateMessage]);

  useEffect(() => {
    fetchPrimaries();
  }, [fetchPrimaries]);

  useEffect(() => {
    if (selectedPrimary) {
      setPrimaryDates({
        openvote: selectedPrimary.openvote ? new Date(selectedPrimary.openvote) : null,
        closevote: selectedPrimary.closevote ? new Date(selectedPrimary.closevote) : null
      });
    }
  }, [selectedPrimary]);

  const handlePrimaryVotingToggle = async (primaryId: number, parentElectionId: number, votesactive: boolean) => {
    if (!primaryId) {
      toast.error('Invalid primary ID');
      return;
    }
    
    try {
      setIsLoading(true);
      const currentStatus = selectedPrimary?.status || 4;
      
      // Use the primary ID directly instead of the parent election ID
      await updatePrimaryElectionStatus(primaryId, currentStatus, votesactive);
      toast.success(`Primary voting ${votesactive ? 'enabled' : 'disabled'} successfully`);
      
      // Just refresh the data instead of trying to manually update the selected primary
      await fetchPrimaries();
      
      // Clear selected primary to avoid stale data
      setSelectedPrimary(null);
    } catch (error) {
      console.error('Error updating primary voting status:', error);
      toast.error('Failed to update primary voting status');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdatePrimaryDates = async () => {
    if (!selectedPrimary?.id) {
      toast.error('Invalid primary ID');
      return;
    }
    
    try {
      setIsLoading(true);
      
      // Use the primary ID directly instead of the parent election ID
      await updatePrimaryElection(selectedPrimary.id, {
        openvote: primaryDates.openvote || undefined,
        closevote: primaryDates.closevote || undefined
      });
      toast.success('Primary election dates updated successfully');
      
      // Just refresh the data instead of trying to manually update the selected primary
      await fetchPrimaries();
      
      // Clear selected primary to avoid stale data
      setSelectedPrimary(null);
    } catch (error) {
      console.error('Error updating primary dates:', error);
      toast.error('Failed to update primary dates');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-8">
      <Toaster position="top-right" />
      
      {/* Notification Message */}
      {updateMessage && (
        <div className="p-4 bg-green-100 text-green-800 rounded text-center">
          {updateMessage}
        </div>
      )}
      
      {/* Loading indicator */}
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-xl">
            <div className="flex items-center space-x-4">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
              <p className="text-gray-700 dark:text-gray-300">Processing...</p>
            </div>
          </div>
        </div>
      )}
      
      {/* Header with refresh button */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-bold text-gray-900 dark:text-gray-100">Primary Elections</h2>
          <button
            onClick={fetchPrimaries}
            disabled={isLoading}
            className="px-4 py-2 text-sm bg-blue-50 hover:bg-blue-100 text-blue-700 rounded-lg flex items-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>
            Refresh
          </button>
        </div>
      </div>
      
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Primaries List Panel */}
        <div className="lg:col-span-1">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4 h-full">
            <h3 className="text-lg font-semibold mb-4 pb-2 border-b border-gray-200 dark:border-gray-700">
              Primary Elections
            </h3>
            
            {primaries.length === 0 ? (
              <div className="text-center py-8">
                <p className="text-gray-500 dark:text-gray-400">No primary elections found</p>
              </div>
            ) : (
              <div className="space-y-3 max-h-[600px] overflow-y-auto pr-2">
                {primaries.map(primary => (
                  <div 
                    key={primary.id}
                    onClick={() => setSelectedPrimary(primary)}
                    className={`p-3 rounded-lg cursor-pointer transition-colors
                      ${selectedPrimary?.id === primary.id 
                        ? 'bg-indigo-50 dark:bg-indigo-900/20 border-l-4 border-indigo-600' 
                        : 'bg-gray-50 dark:bg-gray-700/50 hover:bg-gray-100 dark:hover:bg-gray-700'
                      }`}
                  >
                    <div className="flex justify-between items-start">
                      <div>
                        <h4 className="font-medium">{primary.title}</h4>
                        <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                          ID: {primary.id} • For election: {primary.election.title}
                        </p>
                      </div>
                      <div className="flex flex-col items-end">
                        <span className={`text-xs px-2 py-1 rounded-full font-medium 
                          ${primary.votesactive 
                            ? 'bg-green-100 text-green-800 dark:bg-green-900/50 dark:text-green-200' 
                            : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/50 dark:text-yellow-200'
                          }`}
                        >
                          {primary.votesactive ? 'Voting Active' : 'Voting Inactive'}
                        </span>
                        <span className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                          {primary.candidateCount} candidates
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        
        {/* Primary Detail Panel */}
        <div className="lg:col-span-2">
          {selectedPrimary ? (
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6 h-full">
              <div className="flex justify-between items-start mb-6">
                <div>
                  <h3 className="text-xl font-bold">{selectedPrimary.title}</h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                    Status: <span className="font-medium">{selectedPrimary.statusName || 'Unknown'}</span>
                  </p>
                </div>
                <div className="flex space-x-2">
                  <button 
                    onClick={() => window.open(`/primary/${selectedPrimary.id}`, '_blank')}
                    className="px-3 py-1 text-xs bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 rounded"
                  >
                    View Frontend
                  </button>
                </div>
              </div>
              
              <div className="mb-6">
                <h4 className="font-medium mb-2">Description</h4>
                <p className="text-gray-600 dark:text-gray-300 text-sm p-3 bg-gray-50 dark:bg-gray-700/50 rounded">
                  {selectedPrimary.description || 'No description available'}
                </p>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Voting Status Controls */}
                <div className="bg-gray-50 dark:bg-gray-700/30 p-4 rounded-lg">
                  <h4 className="font-medium mb-3">Voting Status</h4>
                  
                  <div className="flex items-center justify-between mb-4">
                    <span className="text-sm text-gray-600 dark:text-gray-400">Current Status:</span>
                    <span className={`text-xs px-2 py-1 rounded-full font-medium 
                      ${selectedPrimary.votesactive 
                        ? 'bg-green-100 text-green-800 dark:bg-green-900/50 dark:text-green-200' 
                        : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/50 dark:text-yellow-200'
                      }`}
                    >
                      {selectedPrimary.votesactive ? 'Voting Active' : 'Voting Inactive'}
                    </span>
                  </div>
                  
                  <div className="space-y-2">
                    <button
                      onClick={() => handlePrimaryVotingToggle(selectedPrimary.id, selectedPrimary.parent_election_id, true)}
                      disabled={isLoading || selectedPrimary.votesactive}
                      className={`px-4 py-2 rounded text-white w-full ${
                        isLoading || selectedPrimary.votesactive
                          ? 'bg-gray-400 cursor-not-allowed'
                          : 'bg-green-600 hover:bg-green-700'
                      }`}
                    >
                      Enable Voting
                    </button>
                    
                    <button
                      onClick={() => handlePrimaryVotingToggle(selectedPrimary.id, selectedPrimary.parent_election_id, false)}
                      disabled={isLoading || !selectedPrimary.votesactive}
                      className={`px-4 py-2 rounded text-white w-full ${
                        isLoading || !selectedPrimary.votesactive
                          ? 'bg-gray-400 cursor-not-allowed'
                          : 'bg-red-600 hover:bg-red-700'
                      }`}
                    >
                      Disable Voting
                    </button>
                  </div>
                </div>
                
                {/* Voting Schedule */}
                <div className="bg-gray-50 dark:bg-gray-700/30 p-4 rounded-lg">
                  <h4 className="font-medium mb-3">Voting Schedule</h4>
                  
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
                        Open Vote Date
                      </label>
                      <DatePicker
                        selected={primaryDates.openvote}
                        onChange={(date: Date | null) => {
                          setPrimaryDates(prev => ({ ...prev, openvote: date }));
                        }}
                        showTimeSelect
                        dateFormat="Pp"
                        className="w-full text-sm border border-gray-300 dark:border-gray-600 rounded p-2 bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200"
                        placeholderText="Select open vote date"
                      />
                    </div>
                    
                    <div>
                      <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">
                        Close Vote Date
                      </label>
                      <DatePicker
                        selected={primaryDates.closevote}
                        onChange={(date: Date | null) => {
                          setPrimaryDates(prev => ({ ...prev, closevote: date }));
                        }}
                        showTimeSelect
                        dateFormat="Pp"
                        className="w-full text-sm border border-gray-300 dark:border-gray-600 rounded p-2 bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200"
                        placeholderText="Select close vote date"
                      />
                    </div>
                    
                    <button
                      onClick={handleUpdatePrimaryDates}
                      disabled={isLoading}
                      className={`px-4 py-2 rounded text-white w-full ${
                        isLoading
                          ? 'bg-gray-400 cursor-not-allowed'
                          : 'bg-blue-600 hover:bg-blue-700'
                      }`}
                    >
                      Update Schedule
                    </button>
                  </div>
                </div>
              </div>
              
              {/* Additional Info */}
              <div className="mt-6 bg-gray-50 dark:bg-gray-700/30 p-4 rounded-lg">
                <h4 className="font-medium mb-3">Additional Information</h4>
                
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm text-gray-600 dark:text-gray-400">Primary ID:</p>
                    <p className="font-medium">{selectedPrimary.id}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-600 dark:text-gray-400">Parent Election ID:</p>
                    <p className="font-medium">{selectedPrimary.parent_election_id}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-600 dark:text-gray-400">Candidates:</p>
                    <p className="font-medium">{selectedPrimary.candidateCount || 0}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-600 dark:text-gray-400">Status:</p>
                    <p className="font-medium">{getPrimaryStatusName(selectedPrimary.status)}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6 h-full flex items-center justify-center">
              <div className="text-center">
                <p className="text-gray-500 dark:text-gray-400 mb-2">Select a primary election to manage</p>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrimariesTab; 